.footerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'DM Sans';
  box-sizing: border-box;
  padding: 0px 60px 0px 60px;
  background-color: white;

  .footerSection {
    max-width: 1435px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .footerTopSection {
      column-gap: 10px;
      max-width: 1325px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 42px 0 32px;
      flex-wrap: wrap;

      .footerLogo {
        transform: translateY(-50px);
        margin-right: 40px;
      }

      .footerNav {
        max-width: 650px;
        width: 100%;
        margin: 0px 0 20px;

        p {
          margin-bottom: 12px;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
        }

        .navItems {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          a {
            min-width: 170px;
            box-sizing: border-box;
            margin-bottom: 11px;
            text-decoration: none;
            font-family: 'DM Sans';
            line-height: 150%;
            letter-spacing: 0.05em;
            color: #6c6c6c;

            .linkText {
              width: fit-content;
              border-bottom: 1px solid #ffffff;
            }

            .linkText:hover {
              border-color: #000000;
            }
          }
        }

        .navColumn {
          list-style: none;
          display: flex;
          flex-direction: column;
          row-gap: 12px;
        }
      }
    }

    .footerTopSectionSocials {
      column-gap: 10px;
      max-width: 1325px;
      width: 100%;
      display: flex;
      justify-content: end;
      padding: 42px 0 32px;
      border-bottom: 1px solid #dadada;
      flex-wrap: wrap;

      .followUs {
        .buttonsSection {
          display: flex;
          column-gap: 20px;
        }

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.05em;
          margin-bottom: 8px;
        }

        .socialIcons {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          column-gap: 10px;
          margin-bottom: 24px;

          .socialIcon {
            width: 20px !important;
            height: 20px !important;
            padding: 10px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;
            cursor: pointer;

            svg{
              width: 100% !important;
              height: 100% !important;
            }
          }

          .socialIcon:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .footerBottomSection {
      width: 100%;
      padding: 13px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 8px;
        width: 19px;
        height: 19px;
      }
      span {
        color: #8f8f8f;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .footerContainer {
    .footerSection {
      .footerTopSection {
        .footerLogo {
          transform: translateY(-20px);
          margin-right: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .footerContainer {
    padding: 0 20px;
    .footerSection {
      .footerBottomSection {
        width: auto;
        padding: 25px 0 19px;
        align-items: start;
        img {
          margin-top: 4px;
        }
        span {
          text-align: justify;
        }
      }
    }

    .footerTopSection {
      flex-direction: column;
      padding: 0 0 32px;

      .footerLogo {
        transform: translateY(0) !important;
        margin-bottom: 40px;
        margin-right: 0px !important;
      }

      .footerNav {
        margin-left: 0px !important;

        p {
          text-align: center;
        }

        .navItems {
          flex-direction: column;
          margin-bottom: 30px;

          .link {
            text-align: center;
            display: flex;
            justify-content: center;
          }
        }
      }

      .followUs {
        width: 100%;
        .title {
          text-align: center;
          margin-bottom: 24px !important;
        }

        .socialIcons {
          flex-wrap: wrap;
          justify-content: center;
          max-width: 200px;
          margin: 0 auto;
          row-gap: 17px;
          margin-bottom: 62px;
        }

        .buttonsSection {
          flex-direction: column;
          align-items: center;
          max-width: 100%;
          row-gap: 10px;
        }
      }
    }
  }
}
