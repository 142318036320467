.mintLandContainer {
  .buttons {
    width: 285px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancelButton {
      width: 120px;
      height: 45px;
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
      border-radius: 10px;
      color: #ffffff;
      font-size: 11px;
    }

    .combineButton {
      width: 120px;
      height: 45px;
      background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
      border-radius: 10px;
      color: #ffffff;
      font-size: 11px;
      &:disabled {
        background: linear-gradient(
          270.15deg,
          rgba(9, 52, 88, 0.55) -3.93%,
          rgba(13, 41, 66, 0.55) 99.82%
        );
      }
    }
  }

  position: relative;

  max-width: 649px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 73px 72px 43px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../src/assets/images/mintModalBackground.png');
  background-size: 105% 105%;
  background-repeat: no-repeat;

  height: 15rem;
  overflow-y: auto;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 0px;
    border: none;
    cursor: pointer;
  }
  
  .modalTitle {
    font-family: 'DM Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #161616;
    margin-bottom: 43px;
  }

  .dropzone {
    padding: 3rem;
    width: 15rem;
    height: 15rem;

    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-style: dotted;
    border-color: black;
    border-radius: 2rem;
  }

  .btnsSection {
    display: flex;
    max-width: 265px;
    justify-content: space-between;
    width: 100%;
    button {
      font-family: 'DM Sans';
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #ffffff;
      border-radius: 10px;
      max-width: 120px;
      width: 100%;
      padding: 12px 0;
      text-transform: none;
    }

    @media screen and (max-width:365px) {
      gap: 6px;
    }
    // button:first-child {
    //   background: linear-gradient(
    //     270.15deg,
    //     rgba(9, 52, 88, 0.55) -3.93%,
    //     rgba(13, 41, 66, 0.55) 99.82%
    //   );
    // }
    .mintActiveBtn {
      background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
      &:hover {
        background: linear-gradient(
          90.45deg,
          rgba(11, 46, 76, 0.8) 0.47%,
          rgba(16, 79, 133, 0.624) 104.65%
        );
      }
    }
    .mintDisabledBtn {
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
    }
  }
}

.loaderBackground {
  background: rgba(0, 0, 0, 0.34);
  position: fixed;
  z-index: 112;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 150%;
    color: #042037;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 600px) {
  .mintLandContainer {
    border-radius: 30px;
    width: 92.5%;
    height: 660px;
    overflow-y: auto;
    padding: 30px 26px 20px 26px;

    &::-webkit-scrollbar {
      width: 2px;
      display: none;
    }

    .modalTitle {
      max-width: 219px;
      text-align: center;
    }

    .dropzone {
      padding: 2rem;
      width: 15rem;
      height: 15rem;

      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-style: dotted;
      border-color: black;
      border-radius: 2rem;
    }
  }
  .modalContainer {
    padding: 50px 0 105px;
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modalTitle {
      font-size: 16px;
      max-width: 194px;
      text-align: center;
      margin-bottom: 20px;
    }
    
    .buttonsSection {
      max-width: 273px;
      row-gap: 26px;
      margin: 80px 0 80px;
      .marketplaceBtn {
        max-width: 273px;
        column-gap: 19px;
        p {
          font-size: 16px;
        }
      }
    }
  }
  .closeButton {
    top: 5px;
    right: 0px;
  }
}
@media screen and (max-height: 820px) and (min-width: 600px) {
  .mintLandContainer {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      margin-right: 20px;
    }
    border-radius: 30px 0 0 30px;
  }
}
@media screen and (max-height: 750px) {
  .mintLandContainer {
    height: 100vh;
  }
}
