.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}
.shareModalContainer {
  position: absolute;
  top: 70px;
  left: 50%;
  width: 420px;
  height: 195px;
  transform: translate(-50%);
  background: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;
  padding: 30px 30px 60px 30px;
  z-index: 600;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.34));
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    position: absolute;
    top: 26px;
    right: 30px;
    cursor: pointer;
  }

  .shareModalTitle {
    font-family: "Dm Sans";
    font-weight: 700 !important;
    font-size: 24px;
    color: #111111;
  }

  .iconContainer {
    margin-top: 30px;
    margin-left: 120px;
    gap: 15px;
  }
}
.copyText {
  position: absolute;
  display: none;
  top: -20px;
  left: 0;
  font-size: 10px;
  width: 100px;
  text-align: center;
  justify-content: center;
  color: rgb(75, 75, 75);
  font-family: "DM Sans";
}

@media screen and (max-width: 600px) {
  .shareModalContainer {
    position: fixed;
    top: 90px;
    left: 50%;
    width: 90%;
    height: 270px;
    transform: translate(-50%);
    border-radius: 10px;
    padding: 30px 54px 60px 54px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.34));

    .close {
      top: 16px;
      right: 15px;
    }

    .shareModalTitle {
      font-size: 16px;
    }

    .iconContainer {
      display: grid;
      max-width: 224px;
      margin-top: 24px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 45px;
      row-gap: 36px;

      div {
        width: 46px;
        height: 46px;
      }
    }
  }
}
