.modalBackground {
  &.contactUs {
    width: 75%;
  }

  padding: 5px;
  background: linear-gradient(
    90.06deg,
    rgba(29, 103, 170, 0.39) 2.17%,
    rgba(163, 207, 244, 0.44) 92.93%
  );
  border-radius: 30px;
  .content {
    max-width: 690px;
    width: 100%;
    height: auto;
    text-align: center;
    box-sizing: border-box;
    padding: 100px 190px 100px 190px;
    outline: none;
    position: relative;
    background-color: #ffffff;
    z-index: 1;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contentBackground {
      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/images/modalBackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 30px;
    }
    .textContainer {
      width: 460px;
      .title {
        max-width: 460px;
        width: 100%;
        font-size: 22px !important;
        font-weight: 700;
        margin-top: 23px;
        margin-bottom: 25px !important;
      }
      .subTitle {
        max-width: 460px;
        font-size: 19px !important;
        font-weight: 400 !important;
      }
    }

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      cursor: pointer;
      z-index: 5;
      @media (max-width: 600px) {
        top: 5px;
        right: 0px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .modalBackground {
    .content {
      padding: 36px 38px 25px 36px;
      .textContainer {
        width: auto;
        .title {
          font-size: 16px !important;
          margin-bottom: 10px !important;
        }
        .subTitle {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .modalBackground {
    .content {
      width: 100%;
      .confirmIcon {
        width: 50px;
        height: 50px;
      }
    }
    .closeButton {
      top: 5px;
      right: 0px;
    }
  }
}
