.mainContainer {
  width: 100%;
  padding-top: 100px;
  box-sizing: border-box;

  .bodyContainer {
    width: 100%;
    padding: 20px 100px;
    display: flex;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    .firstSection {
      width: 60%;
      box-sizing: border-box;

      .affiliateProgramContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .affiliateProgramHeader {
          margin-top: 30px;
          font-size: 48px;
          font-weight: 700;
        }

        .affiliateProgramText {
          font-size: 28px;
          margin-top: 20px;
          font-weight: 400;
          text-align: justify;
        }

        .buttonSection {
          width: 100%;
          display: flex;
          justify-content: center;
          column-gap: 40px;
          margin-top: 40px;
        }

        .button {
          width: 100%;
          min-width: 160px;
          align-self: center;
          margin-top: 30px;
          margin-bottom: 30px;
          text-transform: none;
          color: #ffffff;
          border-radius: 10px;
          padding: 20px 0;
          font-size: 25px;
          background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);

          &:hover {
            background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
          }
        }
      }
    }

    .secondSection {
      margin-left: 30px;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;

      .secondSectionImg {
        width: 100%;
        max-height: 520px;
      }
    }
  }

  .tutorialSection {
    display: flex;
    width: 100%;
    padding: 30px;
    justify-content: center;
    column-gap: 20vw;
    background-color: #0b3456;
    box-sizing: border-box;

    .tutorialBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      .signupIcon {
        width: 140px;
        height: 120px;
      }

      .promoteLinkIcon,
      .earnIcon {
        width: 80px;
        height: 120px;
      }

      .tutorialText {
        justify-self: flex-end;
        color: #ffffff;
        font-size: 26px;
      }
    }
  }
}

.container {
  margin-bottom: 100px;

  .contentSection {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 56px;
    column-gap: 100px;
    padding-top: 100px;
    box-sizing: border-box;

    .contentBackground {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/modalBackground.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .mainContainerBackground {
      width: 100%;
      padding: 5px;

      .mainContainer {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-radius: 30px;
        padding: 36px;
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        .boxTitle {
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          line-height: 150%;
          margin-bottom: 30px;
        }
        .description {
          width: 60%;
          text-align: center;
          margin-bottom: 40px;
          font-size: 18px;
          font-family: 'DM Sans';
        }

        .linkBox {
          border: 0.7px solid #0b3456;
          border-radius: 30px;
          margin-bottom: 30px;
          max-width: 560px;
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          align-items: center;
          padding-right: 0;
          color: #adacac;
          text-decoration: underline;
          font-size: 16px;
          font-family: 'DM Sans';

          .input {
            width: 100%;
          }

          .copyIcon {
            padding: 0 !important;

            :hover {
              background-color: none !important;
            }

            .icon {
              fill: red;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .mainContainer {
    .bodyContainer {
      .firstSection {
        .affiliateProgramContainer {
          flex-direction: column;

          .affiliateProgramHeader {
            font-size: 40px;
          }

          .affiliateProgramText {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .mainContainer {
    .bodyContainer {
      padding: 30px;

      .firstSection {
        .affiliateProgramContainer {
          flex-direction: column;

          .affiliateProgramHeader {
            font-size: 32px;
          }

          .affiliateProgramText {
            font-size: 18px;
          }

          .buttonSection {
            width: 100%;
            display: flex;
            justify-content: center;
            column-gap: 20px;

            .button {
              min-width: 170px;
              align-self: center;
              margin-top: 30px;
              padding: 10px 22px;
              font-size: 20px;
            }
          }
        }
      }

      .secondSection {
        width: 25%;
      }
    }

    .tutorialSection {
      .tutorialBox {
        .signupIcon {
          width: 120px;
          height: 80px;
        }

        .promoteLinkIcon,
        .earnIcon {
          width: 100px;
          height: 80px;
        }

        .tutorialText {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    .container {
      .contentSection {
        .mainContainer {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .mainContainer {
    padding-top: 40px;

    .bodyContainer {
      .firstSection {
        .affiliateProgramContainer {
          flex-direction: column;

          .affiliateProgramHeader {
            font-size: 22px;
          }
          .affiliateProgramText {
            font-size: 14px;
          }
          .buttonSection {
            flex-direction: column;
            column-gap: 10px;
            margin-top: 10px;
            .button {
              min-width: 120px;
              margin-top: 10px;
              margin-bottom: 0px;
              text-transform: none;
              color: #ffffff;
              border-radius: 6px;
              padding: 6px 20px;
              font-size: 14px;
            }
          }
        }
      }

      .secondSection {
        margin-left: 10px;
        width: 38%;
        margin-top: 20px;
      }
    }

    .tutorialSection {
      padding: 20px;
      column-gap: 5vw;

      .tutorialBox {
        .signupIcon {
          width: 50px;
          height: 30px;
        }

        .promoteLinkIcon,
        .earnIcon {
          width: 40px;
          height: 30px;
        }

        .tutorialText {
          margin-top: 16px;
          font-size: 12px;
        }
      }
    }
  }
  .container {
    margin-bottom: 82px;

    .contentSection {
      width: 100%;
      max-width: none;
      padding-top: 60px;

      .mainContainerBackground {
        max-width: 315px;
        .mainContainer {
          padding: 0 26px;
          max-height: 476px;
          background-image: url(../../assets/images/affiliateMobile.svg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          .boxTitle {
            font-size: 16px;
            margin-bottom: 13px;
          }

          .description {
            font-size: 14px;
            max-width: 229px;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 51px;
          }

          .linkBox {
            max-width: 282px;
            width: 100%;
            padding: 3px;
            p {
              width: 100%;
              font-size: 12px;
            }
            .copyIcon {
              width: 16px !important;
              display: flex;
              justify-content: end;
              img {
                width: 16px;
                height: 18px;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
    .contentBackground {
      display: none;
    }
    .titleWrapper {
      padding: 120px 0 0 0;
      .title {
        text-align: center;
        font-size: 18px;
        margin-bottom: 44px;
        font-weight: 700;
      }
    }
  }
}

.chooseWalletContainer {
  width: 640px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  position: relative;
  padding: 41px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modalTitle {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #323e51;
    text-align: center;
    margin-bottom: 21px;
  }
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    cursor: pointer;
  }
  .line {
    width: 100%;
    height: 1px;
    background: rgba(8, 54, 92, 0.8);
  }
  .buttonsSection {
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    margin: 58px 0;
    max-width: 500px;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .chooseWalletContainer {
    padding: 30px 20px 105px;
    width: 307px;
    .modalTitle {
      max-width: 187px;
    }
  }
}
