.modalBackground {
  padding: 5px;
  background: linear-gradient(
    90.06deg,
    rgba(29, 103, 170, 0.39) 2.17%,
    rgba(163, 207, 244, 0.44) 92.93%
  );

  border-radius: 30px;

  .content {
    max-width: 820px;
    min-height: 431px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 57px 130px 45px 130px;
    outline: none;
    position: relative;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 30px;
    .title {
      margin-bottom: 33px;
      font-family: 'Dm Sans';
      font-weight: 700;
      font-size: 27px !important;
      letter-spacing: 0.03em;
      color: #111111;
    }

    .description {
      margin-bottom: 62px;
      font-family: 'Dm Sans';
      font-weight: 400 !important;
      font-size: 18px;
      letter-spacing: 0.03em;
      color: #111111;
      margin: 30px 0 60px 0 !important;
    }

    .contentBackground {
      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/images/modalBackground.png');
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 30px;
    }

    .formWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      z-index: 1;

      .errorMessageBox {
        height: 25px;

        .errorMessage {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #cb2934;
          text-align: start;
          margin-bottom: 10px;
        }
      }

      .submitBtn {
        background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
        color: white;
        padding: 13px 42px;
        max-width: 137px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 150%;
        font-family: 'DM Sans';
        text-transform: capitalize;
      }

      .disableButton {
        color: #ffffff;
        background: linear-gradient(
          270.15deg,
          rgba(9, 52, 88, 0.55) -3.93%,
          rgba(13, 41, 66, 0.55) 99.82%
        );

        padding: 13px 42px;
        height: 50px;
        max-width: 137px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 150%;
        font-family: 'DM Sans';
        text-transform: capitalize;
      }

      .email {
        border: 0.7px solid #0b345680;
        border-radius: 30px;
        margin-bottom: 30px;
        width: 100%;
        margin-bottom: 10px;
        height: 57px;
        box-sizing: border-box;
        padding: 0 !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 900px) {
  .modalBackground {
    .content {
      padding: 80px 95px 80px 95px;
    }
  }
}

@media (max-width: 600px) {
  .modalBackground {
    .content {
      padding: 40px 35px 90px 35px;

      .title {
        margin-top: 30px !important;
        margin-bottom: 13px !important;
        font-size: 16px !important;
      }

      .description {
        margin: 0 !important;
        margin-bottom: 41px !important;
        font-size: 14px !important;
      }

      .contentBackground {
        background-image: url('../../../../assets/images/modalBackgroundMobile.svg');
        opacity: 1;
      }

      .formWrapper {
        .errorMessageBox {
          height: 18px;
        }

        .submitBtn {
          width: 152px;
          height: 38px;
          font-size: 14px !important;
        }

        .disableButton {
          width: 152px;
          height: 38px;
          font-size: 14px !important;
        }

        .email {
          margin-bottom: 4px;
          height: 39px;
          border-radius: 42px;

          div {
            input {
              box-sizing: border-box;
              padding: 0px 0px 6px 27px !important;
              height: 39px !important;
            }
          }
        }

        .closeButton {
          top: 15px;
          right: 0px;
        }
      }
    }
  }
}

@media screen and (max-height: 400px) {
  .modalBackground {
    .content {
      min-height: auto;
      padding: 10px;
      height: 90%;
    }
  }
}
