.modalBackground {
  padding: 5px;
  background: linear-gradient(
    90.06deg,
    rgba(29, 103, 170, 0.39) 2.17%,
    rgba(163, 207, 244, 0.44) 92.93%
  );
  border-radius: 30px;

  .content {
    max-height: 634px;
    max-width: 610px;
    height: auto;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 90px 80px 90px 80px;
    outline: none;
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .title {
      margin-bottom: 33px;
      font-family: 'Dm Sans';
      font-weight: 700;
      font-size: 20px !important;
      letter-spacing: 0.03em;
      color: #111111;
    }

    .description {
      margin-bottom: 62px;
      font-family: 'Dm Sans';
      font-weight: 400 !important;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: #111111;
      margin: 30px 0 60px 0 !important;
    }

    .contentBackground {
      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/images/modalBackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    border-radius: 30px;

    .formWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .errorMessageBox {
        height: 15px;
        padding: 2px;
        width: 100%;
        margin-left: 20px;
        margin-bottom: 10px;

        .errorMessage {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #cb2934;
          text-align: start;
          margin-bottom: 10px;
        }
      }

      .submitBtn {
        background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
        color: white;
        padding: 13px 42px;
        max-width: 137px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 150%;
        font-family: 'DM Sans';
        text-transform: capitalize;
      }

      .disableButton {
        color: #ffffff;
        background: linear-gradient(
          270.15deg,
          rgba(9, 52, 88, 0.55) -3.93%,
          rgba(13, 41, 66, 0.55) 99.82%
        );

        padding: 13px 42px;
        height: 50px;
        max-width: 137px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 150%;
        font-family: 'DM Sans';
        text-transform: capitalize;
      }

      .email {
        border: 0.7px solid #0b345680;
        border-radius: 30px;
        margin-bottom: 5px;
        height: 42px;
        width: 100%;

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
          height: 42px;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }

        div {
          input {
            box-sizing: border-box;
            padding: 0px 0px 3px 27px !important;
            height: 42px !important;
          }
        }

        box-sizing: border-box;
        padding: 0 !important;
      }

      .commentInput {
        height: 156px;
        border: 0.7px solid #0b345680;
        border-radius: 13px;
        margin-bottom: 10px;
        font-style: italic;
        width: 100%;

        div {
          textarea {
            height: 178px;
            box-sizing: border-box;
            padding: 0px 0px 6px 12px !important;
            height: 39px !important;
          }
        }
      }
    }

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 900px) {
  .modalBackground {
    .content {
      padding: 80px 95px 80px 95px;
    }
  }
}

@media (max-height: 700px) {
  .modalBackground {
    .content {
      padding: 20px 130px 20px 130px;
      .title {
        margin-top: 30px;
        margin-bottom: 13px;
        font-size: 20px !important;
      }
      .description {
        margin-bottom: 13px;
        font-size: 12px !important;
      }
      .formWrapper {
        .errorMessageBox {
          .errorMessage {
            font-size: 10px;
          }
        }
        .submitBtn {
          width: 152px;
          height: 38px;
          font-size: 14px !important;
        }

        .disableButton {
          width: 152px;
          height: 38px;
          font-size: 14px !important;
        }

        .email {
          margin-bottom: 0px;
          height: 39px;
          border-radius: 42px;
          div {
            input {
              box-sizing: border-box;
              padding: 0px 0px 6px 27px !important;
              height: 39px !important;
            }
          }
        }
        .commentInput {
          height: 178px;
          font-style: normal;
          border-radius: 24px;
          margin-bottom: 0px;
        }
      }

      .closeButton {
        top: 15px;
        right: 0px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .modalBackground {
    .content {
      .title {
        margin-top: 30px;
        margin-bottom: 13px;
        font-size: 20px !important;
      }
      .description {
        margin-bottom: 13px;
        font-size: 12px !important;
      }
    }
  }
}

@media screen and(max-width: 700px) {
  .modalBackground {
    .content {
      padding: 20px 130px 20px 130px;
    }
  }
}

@media screen and(max-width: 600px) {
  .modalBackground {
    .content {
      padding: 20px 35px 71px 35px;
      overflow-y: auto;
      max-width: 334px;
      .title {
        margin-top: 50px;
        margin-bottom: 13px;
        font-size: 16px !important;
      }
      .description {
        margin-top: 0px !important;
        margin-bottom: 41px !important;
        font-size: 14px !important;
      }
      .contentBackground {
        background-image: url('../../../../assets/images/modalBackgroundMobile.png');
        opacity: 1;
      }

      .formWrapper {
        .errorMessageBox {
          .errorMessage {
            font-size: 10px;
          }
        }
        .submitBtn {
          width: 152px;
          height: 38px;
          font-size: 14px !important;
        }

        .disableButton {
          width: 152px;
          height: 38px;
          font-size: 14px !important;
        }

        .email {
          margin-bottom: 0px;
          height: 39px;
          border-radius: 42px;
          div {
            input {
              box-sizing: border-box;
              padding: 0px 0px 6px 27px !important;
              height: 39px !important;
            }
          }
        }
        .commentInput {
          height: 178px;
          font-style: normal;
          border-radius: 24px;
          margin-bottom: 0px;
        }
      }

      .closeButton {
        top: 15px;
        right: 0px;
      }
    }
  }
}

@media screen and (max-height: 560px) {
  .modalBackground {
    .content {
      min-height: auto;
      padding: 10px;
      height: 90%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
