.modalContainer {
  max-width: 679px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  background: white;
  box-shadow: 0px 6px 20px rgba(11, 52, 86, 0.16);
  border-radius: 30px;
  padding: 77px 0 44px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .closeIcon {
    position: absolute;
    top: 29px;
    right: 33px;
    cursor: pointer;
  }

  .confirmIcon {
    margin-bottom: 23px;
  }
  p {
    font-family: "DM Sans";
    line-height: 150%;
  }
  p:nth-of-type(1) {
    font-weight: 700;
    font-size: 22px;
    color: #161616;
    margin-bottom: 18px;
  }
  p:nth-of-type(2) {
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    color: #000000;
    margin-bottom: 64px;
  }
  p:nth-of-type(3) {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    span {
      cursor: pointer;
      text-decoration: underline;
      color: #005195;
    }
  }
}

@media screen and (max-width: 800px) {
  .modalContainer {
    width: 92.5%;
    padding: 130px 0 33px;

    .closeIcon {
      right: 23px;
      top: 26px;
    }

    p:nth-of-type(2) {
      margin-bottom: 87px;
      font-size: 16px;
    }
    p:nth-of-type(1) {
      font-size: 18px;
    }
    p:nth-of-type(3) {
      font-size: 12px;
    }
  }
}
