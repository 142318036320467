.royaltyInputContainer {
  width: 100%;
  position: relative;
  display: flex;
  .infoIconBox {
    cursor: pointer;
    width: 19px;
    height: 19px;
    position: absolute;
    right: -26px;
    top: 10px;
    &::before {
      content: '';
      display: none;
    }
    &:hover::before {
      position: absolute;
      content: 'Royalty represents the share creators get out of the proceeds in any secondary sale.';
      font-family: 'DM sans';
      display: block;
      background: #fdfdfd;
      border: 0.7px solid rgba(11, 52, 86, 0.1);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 13px;
      z-index: 99;
      width: 300px;
      top: -26px;
      left: 22px;

      @media screen and (max-width:600px) {
        top: 41px;
        left: -268px;
      }
      @media screen and (max-width:360px) {
        width: 244px;
        top: 41px;
        left: -212px;
      }
    }
    .infoIcon {
      width: 100%;
      height: 100%;
    }
  }

  .placeholderDefault {
    position: absolute;
    display: flex;
    top: 8px;
    left: 27px;
    font-size: 14px;
    pointer-events: none;
    color: #78777793;
    .red {
      color: red;
    }
  }
  
  .percentage {
    position: absolute;
    display: flex;
    top: 8px;
    right: 27px;
    font-size: 14px;
    pointer-events: none;
    color: #78777793;
  }
  .arrowButtonsContainer {
    position: absolute;
    right: 10px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 38px;
    .arrowDropUpIcon {
      margin: 0px 0px 4px 2px;
      cursor: pointer;
    }
    .arrowDropDownIcon {
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
  .royaltyInput {
    border: 0.7px solid #0b345680;
    border-radius: 33px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 32px;
    div {
      &::before {
        border-bottom: none;
      }
      &::after {
        border-bottom: none;
      }
      input {
        box-sizing: border-box;
        padding-left: 26px;
        height: 37px;
        &::placeholder {
          font-size: 14px;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}


