.wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    max-width: 100% !important;
    width: 100%;
    margin: auto;
    background-color: #f5f5f5;
  
    .container {
      width: 80%;
      display: flex;
      flex-direction: column;
      padding-top: 160px;
      margin: auto;
      min-height: 56vh;

      .ownerBox {
        display: flex;
        
        .ownerHeader {
          font-size: 16px;
          color: #161616b2;
        }

        .ownerName {
          font-size: 16px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 120px;
        }

        .walletAddress {
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .results {
        display: flex;
        max-width: 1170px;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 13px;
        row-gap: 26px;
        margin: 19px 0px;

        .card {
          max-width: 204px;
          height: 300px;
          width: 100%;
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 9px;
          overflow: hidden;
          position: relative;
          padding-bottom: 10px;

          .showMoreWindow {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #305c76f2;
            transform: translateY(354px);
            transition: all 0.4s;
            cursor: pointer;

            .showMoreText {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: white;
              font-size: 16px;
              padding: 8px;
              cursor: pointer;
              font-family: 'DM Sans';
            }
          }

          .img {
            width: 184px;
            height: 184px;
            margin: 10px 11px 5px 10px;
          }

          .name {
            margin: 3px 11px;
            cursor: default;

            .showMore {
              cursor: pointer;
            }

            img {
              width: 19px;
            }
          }

          .name {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 600;
          }

          .iconBox {
            display: flex;
            align-items: center;
            column-gap: 4px;
            margin-left: 10px;

            .usdcLogo {
              width: 15px;
            }

            .solPrice {
              font-weight: 700;
              color: #161616;
              font-family: DM Sans;
            }

            .usdPrice {
              color: #161616;
              font-weight: 400;
              font-family: DM Sans;
              opacity: 0.61;
            }
          }

          .btn {
            margin-top: 23px;
            width: 100%;
            background: #0b3456;
            border-radius: 0px 0px 9px 9px;
            text-transform: none !important;
            color: white;
            position: absolute;
            bottom: 0;
            font-family: 'DM Sans';
          }

          .btn:hover {
            background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
          }

          .activeMint {
            margin-top: 23px;
            width: 100%;
            border-radius: 0px 0px 9px 9px;
            text-transform: none !important;
            color: white;
          }

          &:hover {
            .showMoreWindow {
              transform: translateY(0);
            }
          }
        }
      }
      
    }
}

.cardCoordinates {
  margin-left: 10px;
  display: flex;
  align-items: center;
  column-gap: 4px;

  .coordinates {
    color: #2c2c2c;
    font-family: DM Sans;
    font-weight: 500;
  }

  img {
    max-width: 18px;
  }
}


