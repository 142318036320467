@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.networkPlatformContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // padding-right: 150px;
  display: flex;
  // justify-content: space-between;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;

  .networkPlatformBox {
    // width: 60%;
    padding-bottom: 10px;

    .networkPlatformTitle {
      font-size: 25px;
      font-weight: 600;
      font-family: "DM Sans" sans-serif;

      &.itroductionOfToken {
        margin-top: 112px;
      }
    }

    .networkPlatformSecondTitle {
      font-size: 20px;
      font-weight: 600;
      font-family: "DM Sans" sans-serif;
    }

    .mobileTokenDistributionTitle {
      font-size: 18px;
      font-weight: 700;
      margin-top: 48px;
      margin-bottom: 20px;
    }

    .mobileChartImg {
      width: 100%;
      height: 100%;
    }
  }

  .networkPlatformBoxSubTitle {
    font-size: 18px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 24px;
  }

  .tokenDistributionBox {
    max-width: 470px;
    width: 35%;

    img {
      margin-top: 50px;
      max-width: 470px;
      width: 100%;
    }
  }
}

.secondTitle {
  margin-top: 40px;
}

.listItemText {
  padding-left: 64px;

  span {
    font-family: "DM Sans";
  }

  span {
    font-weight: 500 !important;
    font-size: 18px;
  }
}

.list {
  list-style-type: none;
  counter-reset: my-counter;
}

.listItem {
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 184px;
  max-width: 600px;
}

.listItem:before {
  content: counter(my-counter);
  counter-increment: my-counter;
  background: linear-gradient(86.21deg, #539dcc 7.47%, #66c3f3 115.23%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-weight: 700;
  font-size: 28px;
  position: absolute;
  left: 0;
  top: auto;
}

@media (max-width: 1200px) {
  .networkPlatformContainer {
    padding-right: 0;
    flex-direction: column;
    .networkPlatformBox {
      width: 100%;
    }
    .secondTitle {
      width: 100%;
    }
    .tokenDistributionBox {
      width: 100%;
      .tokenDistribitionImgBox {
        width: 100%;

        img {
          margin-top: 20px;
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  .list {
    width: 100%;
    margin-top: 12px;
  }

  .listItem {
    font-size: 16px;
    margin-top: 20px;
    padding-left: 44px;
  }

  .listItemText {
    padding-left: 34px;
  }

  .listItem:before {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .networkPlatformContainer {
    flex-direction: column;

    .networkPlatformBox {
      width: 100%;

      .networkPlatformTitle {
        font-size: 18px;
        font-weight: 700;
        font-family: "DM Sans" sans-serif;

        &.itroductionOfToken {
          margin-top: 47px;
        }
      }

      .networkPlatformSecondTitle {
        font-size: 18px;
        font-weight: 700;
        font-family: "DM Sans" sans-serif;
      }
    }

    .tokenDistributionBox {
      margin-top: 40px;
      width: 100%;
    }

    .tokenDistributionBox {
      margin-top: 30px;
    }

    .secondTitle {
      width: 100%;
    }

    .list {
      width: 100%;
      margin-top: 12px;
    }

    .listItem:before {
      content: counter(my-counter);
      counter-increment: my-counter;
      background: linear-gradient(86.21deg, #539dcc 7.47%, #66c3f3 115.23%);
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px !important;
      height: 44px !important;
      font-weight: 700;
      font-size: 20px;
      position: absolute;
      left: 0;
      top: auto;
    }

    .listItemText {
      padding-left: 45px;

      span {
        font-weight: 500 !important;
        font-size: 16px;
      }
    }

    .tokenDistributionBox {
      width: 100%;
      max-width: 100%;
    }

    .tokenDistribitionImgBox {
      .tokenImgMobile {
        margin-top: 20px;
        max-width: 100%;
        width: 100% !important;
        height: 100% !important;
        margin-right: 0px;
      }
    }
  }
}
