.pageContainer {
  .pageImg {
    width: 75%;
  }
  .roadmapTitle {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 29px;
    font-family: 'DM Sans';
  }

  .yearText {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 60px;
    color: #0b3456;
    font-family: 'DM Sans';
  }

  .pageRow {
    display: flex;
    max-width: 1440px;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 87px;

    .list {
      width: 301px;

      & > .listItem:first-child {
        .listItemText {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 24px;
          line-height: 150%;
          letter-spacing: 0.05em;
          color: #0c3a63;

          font-weight: 600 !important;
          margin-top: 56px !important;
          padding-left: 20px !important;
        }
      }

      .listItemText {
        font-family: 'DM Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #0c3a63;
        list-style: circle;
      }

      .listItem {
        display: flex;
        column-gap: 18px;
        padding: 0px;

        .listiyemicon {
          min-width: 0;

          .icon {
            font-size: 5px;
            color: #0c3a63;
            min-width: 0px;
          }
        }
      }
    }
  }
}
.bold {
  font-weight: 600 !important;
  margin-top: 56px !important;
  padding-left: 20px !important;
}

@media screen and (max-width: 600px) {
  .pageContainer {
    .yearText {
      font-size: 18px;
      margin-bottom: 32px;
    }

    .mobilePageImg {
      width: 20px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .roadmapTitle {
      font-size: 25px;
      margin-bottom: 16px;
    }

    .mobileContainer {
      display: flex;
      column-gap: 48px;
      position: relative;
      margin-bottom: 30px;
      .leftLine {
        margin-left: 10px;
        height: auto;
        width: 5px;
        background: linear-gradient(to bottom, #1d67aa 2.17%, #a3cff4 92.93%);
      }
      .pageRow {
        flex-direction: column;
        row-gap: 50px;

        ul {
          padding: 0;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
        }

        .list {
          width: 100%;
          margin-bottom: 18px;

          .quarterCircle {
            position: absolute;
            left: -60px;
            width: 20px;
            height: 20px;
            background-color: #000000;
            border-radius: 50%;
          }
          & > .listItem:first-child {
            .listItemText {
              font-size: 16px;
              color: #161616;
            }
          }

          .listItemText {
            font-size: 14px;
            color: #161616;
          }
        }
      }
    }
  }
  .bold {
    margin-top: 0 !important;
  }
}
