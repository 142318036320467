.button {
    cursor: pointer;
    position: relative;

    .white {
        filter: brightness(0) invert(1);
    }

    &.disabled {
        pointer-events: none;
    }

    .tooltip {
        display: none;
        position: absolute;
        padding: 5px;
        white-space: nowrap;
        width: max-content;
        font-size: 11px;
        border-radius: 5px;
        background: #019fff;
        color: white;
        letter-spacing: 1px;
        left: 0;
        top: 0;
        transform: translate(-17px, -37px);

        .arrow {
            clip-path: polygon(100% 0, 0 0, 50% 100%);
            display: block;
            width: 16px;
            height: 6px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 182%);
            background: #019fff;
        }

        &.show {
            display: block;
        }
    }
}
