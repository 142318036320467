.termsOfServiceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mainHeader {
    width: 100%;
    height: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);

    .header {
      font-size: 36px;
      font-weight: 700;
      color: #ffffff;
    }
  }

  .termsOfServiceContent {
    box-sizing: border-box;
    margin: 60px;
    max-width: 1440px;

    .termsOfServiceDescription {
      margin-top: 18px;
      display: flex;

      .description {
        width: 70%;
        margin-right: 180px;
        font-size: 20px;
        color: #161616;
        a {
          color: #6098f4;
        }
      }

      .imgContainer {
        justify-content: flex-end;

        img {
          margin-top: 30px;
        }
      }
    }

    .contentDrawerContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 100px;
      margin-bottom: 90px;
    }
  }
}
@media screen and (max-width: 800px) {
  .termsOfServiceContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mainHeader {
      width: 100%;
      height: 164px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);

      .header {
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
      }
    }

    .termsOfServiceContent {
      box-sizing: border-box;
      margin: 60px;
      max-width: 1440px;

      .termsOfServiceDescription {
        margin-top: 18px;
        display: flex;

        .description {
          width: 70%;
          margin-right: 60px;
          font-size: 18px;
          color: #161616;
          a {
            color: #6098f4;
          }
        }

        .imgContainer {
          img {
            margin-top: 30px;
            width: 200px;
            height: 250px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .termsOfServiceContainer {
    .mainHeader {
      width: 100%;
      height: auto;
      background: none;

      .header {
        font-size: 22px;
        font-weight: 700;
        color: #0b3456;
      }
    }

    .termsOfServiceContent {
      display: flex;
      flex-direction: column;
      margin: 0;

      .termsOfServiceDescription {
        flex-direction: column;
        margin: 20px;

        .description {
          font-size: 16px;
          margin: 0;
          width: 100%;
        }

        .imgContainer {
          align-self: center;

          img {
            margin-top: 43px;
            width: 149px;
            height: 172px;
            margin-bottom: 65px;
          }
        }
      }
      .contentDrawerContainer {
        margin: 0;
      }
    }
  }
}
