.userBenefitsContainer {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-weight: 400;
    display: flex;
    overflow: hidden;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
    .userBenefits {
      .userBenefitsTitle {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 24px;
        @media (max-width: 600px) {
          font-size: 20px;
          margin-bottom: 14px;
        }
      }
      margin-right: 50px;
      @media (max-width: 1200px) {
        margin-right: 0;
        margin-bottom: 50px;
      }
  
      span {
        font-weight: 700;
      }
    }
    .technicalToolsUsedListItem {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 0 10px;
      margin-left: 10px;
  
      .listItemText {
        font-family: "DM Sans";
        font-weight: 550;
      }
  
      .listItemMarker {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: black;
      }
    }
    .technicalToolsUsedTitle {
      font-weight: 600;
      font-size: 25px;
      margin-bottom: 7px;
      margin-top: 10px;
      @media (max-width: 600px) {
        font-size: 18px;
        margin-bottom: 7px;
      }
    }
    .technicalToolsImgs {
      display: flex;
      align-items: center;
  
      img {
        margin-right: 43px;
      }
  
      @media (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 10px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        padding: 60px 35px 0px;
        img {
          margin-top: 15px;
          margin-right: 0;
        }
        .technicalToolsLastImg {
          display: grid;
          justify-content: center;
        }
      }
    }
    @media screen and (max-width: 300px) {
      .technicalToolsImgs {
        padding: 0 !important;
      }
    }
  }
  
  
  