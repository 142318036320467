.blockchainSelection {
  margin-bottom: 30px;
}

.blockchainSelectionTitle {
  font-size: 36px !important;
  font-weight: 600 !important;
  margin-bottom: 27px !important;
}
.blockchainSelectionList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  max-width: 1000px;
  @media (max-width: 900px) {
    margin-top: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  .blockchainSelectionListItem {
    display: flex;
    align-items: center;
    // margin-right: 60px;
    // margin-top: 60px;
    @media (max-width: 600px) {
      margin-top: 20px;
    }
    .blockchainSelectionListItemText {
      margin-left: 20px;
      width: 150px;
      word-break: keep-all;
    }
  }
}

@media screen and (max-width: 600px) {
  .blockchainSelectionTitle {
    font-size: 20px !important;
    margin-bottom: 28px !important;
  }

  .description {
    font-size: 16px !important;
    margin-bottom: 77px !important;
  }

  .blockchainSelectionList {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 79px;
    .blockchainSelectionListItem {
      flex-direction: column;
      margin: 0 !important;
      row-gap: 12px;

      .img {
        width: 75px;
        height: 75px;
      }

      .blockchainSelectionListItemText {
        margin: 0 !important;
        width: 100%;
        .textItem {
          font-size: 18px !important;
          margin: 0 !important;
          max-width: 100% !important;
          font-weight: 550 !important;
        }
      }
    }
  }
}


.poweredByAiTitle {
  font-size: 36px !important;
  font-weight: 600 !important;
  margin-bottom: 27px !important;
}

.poweredByAiSecondTitle {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 25px !important;
  margin-top: 25px !important;
}
@media screen and (max-width: 600px) {
  .poweredByAiTitle {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 27px !important;
  }

  .poweredByAiSecondTitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-bottom: 25px !important;
    margin-top: 60px !important;
  }
}