.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 11;

  .navbarContainer {
    max-width: 1464px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    flex-wrap: wrap;

    .navItems {
      display: flex;
      justify-content: space-between;
      max-width: 515px;
      width: 100%;

      .listItem {
        &:hover .navItem {
          border-bottom: 2px solid white;
        }
      }

      .navItem {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        text-decoration: none;
      }

      .activeNavItem {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        text-decoration: none;
        border-bottom: 2px solid #ffffff;
        padding-bottom: 3px;
      }
    }
  }
}

.blackNavbar {
  background: #000000;
}

.blueNavbar {
  background: #0b3456;
}

.mobileNavbar {
  display: flex;
  justify-content: space-between;
  background: #0b3456;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 80;
  padding: 10px 0 17px 27px;
  align-items: center;
  box-sizing: border-box;
  .mobileNavbarLogoContainer {
    display: grid;
    justify-content: center;
  }
  .hamburgerIcon {
    position: absolute;
    right: 28px;
    cursor: pointer;
    z-index: 81;
    &.open {
      animation: selectedMenu 0.3s ease-out both;

      @keyframes selectedMenu {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(90deg);
        }
      }
    }
    &.close {
      animation: closedMenu ease-out both;

      @keyframes closedMenu {
        0% {
          transform: rotate(90deg);
        }
        100% {
          transform: rotate(0);
        }
      }
    }
  }
}

.mobileNavbarOpen {
  position: fixed;
  top: 72px;
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 24px;
  opacity: 0;
  z-index: 90;
  visibility: hidden;
  overflow-y: auto;
  .mobileTitleList {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 62px 0 0 21px;
    overflow: auto;
    .sidebarItem {
      text-decoration: none;
    }
  }

  &.open {
    animation: slide-in-top 0.3s ease-out both;
    visibility: visible;
    @keyframes slide-in-top {
      0% {
        transform: translateY(-700px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &.close {
    animation: closeAnimation ease-out both;

    @keyframes closeAnimation {
      0% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
      100% {
        transform: translateY(-700px);
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

@media screen and (max-width: 1161px) {
  .navbar {
    .navbarContainer {
      .navItems {
        max-width: none;
        width: auto;
        column-gap: 20px;

        .navItem {
          font-size: 12px;
        }

        .activeNavItem {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 964px) {
  .navbar {
    .navbarContainer {
      .navItems {
        max-width: none;
        width: auto;
        column-gap: 20px;
        order: 1;
        flex-wrap: wrap;

        .navItem {
          font-size: 12px;
        }

        .activeNavItem {
          font-size: 12px;
        }
      }
    }
  }
}
