.header {
  position: relative;
  background-color: black;
  width: 100%;
  display: flex;
  height: 800px;
  flex-direction: column;
  align-items: center;

  .loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #ffffff;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    &.hidden {
      display: none;
    }
  }

  .headerBackgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .headerContainer {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 109px;

    .headerLogo {
      margin-top: 149px;
      z-index: 1;

      &.animate {
        animation: headerLogoTextAnimation 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }

      &.hidden {
        visibility: hidden;
      }
    }
    @keyframes headerLogoTextAnimation {
      0% {
        transform: translateY(400px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .headerText {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      letter-spacing: 0.03em;
      color: #ffffff;
      margin-bottom: 32px;
      z-index: 1;

      &.animate {
        animation: headerLogoTextAnimation 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }

      &.hidden {
        visibility: hidden;
      }
    }

    .headerButton {
      text-transform: capitalize;
      display: flex;
      padding: 13px 43px;
      background: white;
      font-family: "DM Sans";
      font-size: 16px;
      font-weight: 700;
      color: #0a3355;
      column-gap: 10px;
      border-radius: 10px;
      max-height: 50px;
      margin-bottom: 291px;

      &:hover {
        color: #ffffff;
        background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
      }

      &.animate {
        animation: headerButtonAnimation 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }

      &.hidden {
        visibility: hidden;
      }
      @keyframes headerButtonAnimation {
        0% {
          transform: translateY(-400px);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .header {
    .headerContainer {
      .headerLogo {
        width: 60%;
      }

      .headerText {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 500px;

    .headerContainer {
      .headerLogo {
        width: 80%;
        margin-top: 0;
      }
      
      .headerText {
        margin-top: 100px;
        font-size: 18px;
      }
    }
  }
}
