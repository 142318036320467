.headerTitle {
  font-weight: 700 !important;
  color: #161616;
  font-size: 36px !important;
  line-height: 150%;
  margin-bottom: 77px !important;
  text-align: center;
}

.partnersWrapper {
  background: #fff;
  height: 100%;
  padding-bottom: 10px;

  .partnersTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    margin-bottom: 77px;
    padding-top: 76px;
  }

  .partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    width: 100%;
    max-width: 1390px;
    margin: 0 auto 122px auto;


    &.hidden {
      visibility: hidden;
    }

    &.animate {
      animation: slide-in-bot 0.9s cubic-bezier(0.55, 0.86, 0.15, 0.94) both;
      animation-delay: 0.3s;
    }

    .arrowBtn {
      margin-top: 20px;
      &.rotate {
        transform: rotate(180deg);
      }
    }
    @keyframes slide-in-bot {
      0% {
        transform: translateY(400px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media (max-width: 375px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .lines {
      // border-bottom: 1px solid rgba(11, 52, 86, 0.6);
      max-width: 1000px;

      // &:nth-last-child(-n+3) {
      //   border-bottom: none;
      // }

      @media (max-width: 375px) {
        border-right: none;
        border-bottom: 1px solid rgba(11, 52, 86, 0.6);

        :last-child {
          border-bottom: none;
        }
      }

      .partnerItem {
        margin: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }

        @media (max-width: 375px) {
          padding: 43px 0px;
        }

        img {
          width: 100%;
          height: 90px;

          @media (max-width: 1000px) {
            width: 48%;
          }

          @media (max-width: 400px) {
            width: 48%;
          }
        }
      }
    }

    .item1 {
      justify-content: center;
    }

    .noHorizontalLine {
      border-bottom: none;
      padding-top: 43px;
      padding-bottom: 43px;
      border-right: 1px solid rgba(11, 52, 86, 0.6) !important;

      @media (max-width: 1000px) {
        padding: 0px;
      }

      @media (max-width: 375px) {
        border-bottom: 1px solid rgba(11, 52, 86, 0.6);
      }
    }

    .noVerticalLine {
      border-right: none;
    }

    .active {
      padding-bottom: 43px;

      @media (max-width: 1000px) {
        padding: 0px;
      }
    }
  }
}

.roadmapContainer {
  max-width: 1640px;
  width: 100%;

  .roadmapContainerYear {
    font-weight: 700;
    margin-bottom: 93px;
    color: #ffffff;
    font-size: 30px;
    font-family: 'DM Sans';
  }
}

.pageContainer {
  background-image: url('../../assets/images/roadmap-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 43px;
  padding: 116px 20px 37px;

  .roadMapTitle {
    font-weight: 700;
    margin-bottom: 93px;
    color: #ffffff;
    font-size: 30px;
    font-family: 'DM Sans';
  }

  .headerTitle {
    font-weight: 700;
    color: #ffffff;
    font-family: 'DM Sans';
    font-size: 36px;
    line-height: 150%;
    letter-spacing: 0.05em;

    &.animate {
      animation: scale-in-center 1.6s cubic-bezier(0.68, 1.15, 0.265, 1.55) both;
    }

    &.hidden {
      visibility: hidden;
    }

    @keyframes scale-in-center {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }
  .pageImg {
    width: 80%;
    opacity: 0;

    &.animate {
      animation: slide-in-left 1.6s cubic-bezier(0.5, 0.985, 0.28, 1.33) both;
      animation-delay: 0.5s;
    }

    &.hidden {
      visibility: hidden;
    }

    @keyframes slide-in-left {
      0% {
        transform: translateX(-200px);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .pageRow {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 50px;
    opacity: 1;
    &.animate {
      animation: slide-in-right 1.6s cubic-bezier(0.5, 0.985, 0.28, 1.33) both;
      animation-delay: 0.5s;
    }

    &.hidden {
      visibility: hidden;
    }

    @keyframes slide-in-right {
      0% {
        transform: translateX(200px);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .list {
      max-width: 241px;
      width: 100%;
      padding: 0;
      position: relative;
      margin-bottom: 60px;
      .quarterCircle {
        position: absolute;
        left: -52px;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 100%;
      }

      .listItemText {
        font-family: 'DM Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        list-style: circle;
      }

      .listItemBoldText {
        font-family: 'DM Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        list-style: circle;

        font-weight: 600 !important;
        margin-top: 56px !important;
        padding-left: 20px !important;
      }

      .listItem {
        display: flex;
        column-gap: 18px;
        padding: 0px;
        margin-bottom: 14px;
      }

      .listItem:first-child {
        margin-bottom: 17px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pageContainer {
    padding: 30px 0px 77px;
    background-image: url('../../assets/images/roadmap-bg.png');
    background-size: cover;

    .headerTitle {
      font-size: 24px !important;
      margin-bottom: 0 !important;
    }

    .moreButton {
      align-self: flex-end;
      color: white;
      text-decoration: underline;
      text-underline-offset: 4px;
      margin-right: 20px;
      font-size: 16px;
      text-transform: none;
      &:active {
        text-decoration: underline;
      }
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .wrapper {
    padding-left: 20px;
    margin-bottom: 40px;

    .roadMapTitle {
      margin-bottom: 60px;
      font-size: 20px;
    }
  }

  .roadmapContainer {
    width: 100%;
    display: flex;

    img {
      margin-left: 10px;
      height: 100%;
    }
    .leftLine {
      margin-left: 10px;
      height: auto;
      width: 5px;
      background: linear-gradient(to bottom, #1d67aa 2.17%, #a3cff4 92.93%);
    }
    .pageRow {
      margin-left: 40px;
      margin-right: 30px;
      &.animate {
        animation: none;
      }

      .list {
        max-width: 100%;
        .listItem {
          margin-bottom: 0;
        }

        .listItemText {
          font-size: 16px;
        }
      }
    }
  }
  .partnersWrapper {
    .partnersTitle {
      margin-bottom: 10px;
    }

    .partners {
      margin: 100;
      grid-template-columns: repeat(1, 1fr);
      .lines {
        margin: 0 60px;
        border-right: none;

        .partnerItem {
          padding: 30px 0 !important;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }

      .lines:last-child {
        border: none;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .roadmapContainer {
    .pageRow {
      .list {
        .listItemText {
          font-size: 13px;
        }
      }
    }
  }
}
