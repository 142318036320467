.discoverContainer {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 188px 30px 188px 30px;

  .contentSection {
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    max-width: 835px;
    width: 100%;
    padding: 107px 0 96px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.animate {
      animation: scale-in-center 1.6s cubic-bezier(0.68, 1.15, 0.265, 1.55) both;
    }

    &.hidden {
      visibility: hidden;
    }

    @keyframes scale-in-center {
      0% {
        transform: scale(0.3);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    .title {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 48px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      max-width: 516px;
    }

    .subtitle {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .description {
      max-width: 720px;
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      opacity: 0;

      &.animate {
        animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-delay: 0.7s;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 40px 0;
    height: auto;

    .contentSection {
      width: 80%;
      padding: 20px;

      .title {
        font-size: 24px;
        margin-bottom: 30px;
      }

      .subtitle {
        font-size: 18px;
      }

      .description {
        font-size: 16px;
      }
    }
  }
}
