.featuresContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-weight: 400;
  overflow: hidden;

  .bottomText {
    font-family: "DM Sans";
    max-width: 845px;
  }

  .featuresTitle {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 35px;
    font-family: "DM Sans";
  }

  .featuresDescription {
    margin-bottom: 50px;
    max-width: 845px;
    font-family: "DM Sans";
    font-size: 16px;
  }

  .divider {
    background-color: #0c3d69;
    max-width: 1106px;
    width: 90%;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .text {
    max-width: 845px;
    font-size: 16px;
    font-family: "DM Sans";
  }

  .subTitle {
    color: #0c4170;
    font-weight: 700;
    font-size: 25px;
    margin-top: 33px;
    font-family: "DM Sans";
  }
  .featuresListItem {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 0 10px;
    margin-left: 10px;
    .listItemText {
      font-size: 16px;
      font-family: "DM Sans";
    }
    .listItemMarker {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: black;
    }
  }
}

.featuresBG {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .featuresContainer {
    .bottomText {
      font-size: 16px;
    }

    .featuresTitle {
      font-size: 20px;
      margin-bottom: 21px;
    }

    .divider {
      width: 100%;
      margin-top: 6px;
      margin-bottom: 15px;
    }

    .list {
      margin-bottom: 40px;

      .featuresListItem {
        .listItemText {
          font-weight: 550;
        }
      }
    }

    .text {
      margin-bottom: 15px;
    }

    .featuresDescription {
      margin-bottom: 40px;
    }

    .subTitle {
      font-size: 18px;
      margin-top: 0;
    }
  }
}
