::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-corner {
  background: #0b3456;
}
::-webkit-scrollbar-track {
  background: #0b3456;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(170.34deg, #0b3456 37.84%, #a3cff4 93.21%);
  border-radius: 28px;
  height: 60px;
}
