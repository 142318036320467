.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background: none;
  z-index: 99;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.34);
  overflow: hidden;
  .contentWrapper {
    margin: 100px 21px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
