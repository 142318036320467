.wrapper {
  display: flex;
  justify-content: center;
  column-gap: 115px;
  width: 100%;
  flex-wrap: wrap;

  .faqHeader {
    max-width: 585px;
    margin-top: 200px;

    .title {
      font-family: 'DM Sans';
      font-size: 45px;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #0c4374;
      width: 500px;
      line-height: 150%;
    }

    .about {
      font-family: 'DM Sans';
      font-size: 18px;
      line-height: 150%;
      color: #161616;
      margin-top: 20px;
    }
  }

  .faqFooter {
    max-width: 752px;
    width: 100%;

    .search {
      font-family: 'DM Sans';
      font-style: italic;
      font-size: 15px;
      letter-spacing: 0.03em;
      color: #161616;
      max-width: 500px;
      width: 100%;
      border: 1px solid #0b2e4c;
      border-radius: 7px;
      margin-top: 82px;
      margin-bottom: 82px;
    }

    .text {
      margin: 10px 0;
      font-family: 'DM Sans';
      font-size: 19px;
      line-height: 150%;
      color: #161616;
      // display: flex;
      letter-spacing: 0.02em;
      max-width: 610px;
    }

    .desktopFaqs {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      .divider {
        border: 1px solid black;
        margin-top: 22px;
      }

      .faqWrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        max-width: 730px;
        width: 100%;

        .hideShowIcon {
          cursor: pointer;
        }
        .titleBox {
          font-weight: 700;
          font-size: 24px;
          color: #0c4374;
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 90%;
    margin: auto;

    .faqHeader {
      max-width: 100%;
      margin-top: 28px;

      .title {
        font-style: normal;
        font-size: 20px;
        width: fit-content;
      }

      .about {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .faqFooter {
      max-width: 100%;
      .text {
        font-size: 16px;
      }
      .search {
        max-width: 100%;
        width: 100%;
        height: 42px;
        margin-top: 44px;
        margin-bottom: 44px;

        input {
          padding: 8px;
          padding-left: 0;
        }
      }
    }

    .faqs {
      .accordion {
        box-shadow: none !important;
        border-top: 1px solid black !important;
        color: #0b2e4c;
        padding: 10px;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        .accordionHeader {
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 550 !important;
          font-size: 18px;
          line-height: 150%;
          color: #0c4374;
        }

        .accordionHeaderBox {
          width: 100%;

          .hideShowIcon {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}
