.modalContainer {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  position: relative;
  width: 640px;
  padding: 41px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modalTitle {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #323e51;
    margin-bottom: 21px;
  }

  .buttonsSection {
    display: flex;
    flex-direction: column;
    margin: 58px 0;
    row-gap: 23px;
    width: 366px;

    .button {
      width: 100%;
      background: rgba(207, 198, 207, 0.2);
      border-radius: 10px;
      padding: 8px 0px 8px 34px;
      justify-content: flex-start;
      display: flex;
      column-gap: 12px;
      p {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #111111;
        text-transform: none;
      }
    }

    .selectedButton {
      background: linear-gradient(
        90.45deg,
        rgba(11, 46, 76, 0.8) 0.47%,
        rgba(16, 79, 133, 0.78) 104.65%
      ) !important;
      p {
        color: #ffffff;
      }
    }
  }

  .continueButton {
    text-transform: none;
    a {
      font-family: 'DM Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
      padding: 13px 32px;
      background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
      border-radius: 10px;
      text-decoration: none;
      margin-top: 24px;
    }
  }

  .disabledContinueButton {
    a {
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
    }
  }

  .closeButton {
    position: absolute;
    top: 27px;
    right: 10px;
    border: none;
    cursor: pointer;
  }

  .line {
    width: 100%;
    background: rgba(8, 54, 92, 0.8);
    height: 1px;
  }
}

@media screen and (max-width: 600px) {
  .modalContainer {
    width: 347px;
    .modalTitle {
      max-width: 190px;
      font-size: 16px;
      text-align: center;
    }

    .buttonsSection {
      width: 273px;
      margin: 80px 0;
      .button {
        width: 100%;
        background: rgba(207, 198, 207, 0.2);
        border-radius: 10px;
        padding: 8px 0px 8px 34px;
        justify-content: flex-start;
        display: flex;
        column-gap: 12px;
        img {
          width: 30px;
          height: 30px;
        }
        p {
          font-size: 16px;
        }
      }
    }

    .continueButton {
      a {
        width: 179px;
        font-size: 14px;
      }
    }

    .line {
        width: 85%;
    }
  }
}
