.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  max-width: 100% !important;
  width: 100%;
  margin: auto;
  background-color: #f5f5f5;

  .container {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    margin: auto;
    min-height: 56vh;

    .leftSide {
      width: 100%;
      display: flex;
      gap: 10px;
      height: fit-content;
      margin-right: 96px;

      .title {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 150%;
        color: #0b3456;
        margin-right: 92px;
      }

      .searchBar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .searchWrapper {
          display: flex;
          flex-direction: column;
          row-gap: 7px;
          max-width: 705px;
          width: 100%;
          margin-right: 12px;
          max-height: 46px;

          .searchResultbox {
            max-width: 705px;
            padding: 25px 27px;
            z-index: 100;
            background: #ffffff;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
            border-radius: 5px 5px 8px 8px;

            .searchResultItem {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 21px;
              color: #7b91a3;
              margin-bottom: 7px;
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 6px 13px;
              border-radius: 10px;

              img {
                width: 20px;
              }

              .matched {
                background: none;
                color: #0b3456;
                font-weight: 600;
              }
            }

            .searchResultItem:last-child {
              margin: 0;
            }

            .searchResultItem:hover {
              background-color: #cfdbe5;
            }
          }

          .search {
            display: flex;
            width: 100%;
            max-height: 46px;
            height: 100%;
            border: 1px solid #323e51;
            border-radius: 33px;
            padding: 1px;

            .searchicon {
              margin-left: 26px;
              padding: 0 6px 0 0;

              svg {
                width: 21px;
                color: #0b2e4c;
              }
            }

            .closeIcon {
              color: #000000;
            }

            .input {
              width: 100%;
              font-style: italic;
            }

            .searchButton {
              width: 105px;
              align-self: flex-end;
              height: 100%;
              background: #0b3456;
              border: 1px solid #0b3456;
              border-radius: 33px;
              color: #ffffff;
            }

            .searchButton:hover {
              background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              transition: background-color 5000s ease-in-out 0s;
            }
          }
        }

        .selectSection {
          height: 35px;
          display: flex;
          border: 1px solid #d6d7d9;
          border-radius: 5px;
          margin-left: auto;
          cursor: pointer;

          .chartImg {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ced6dd;
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
            padding: 0px 15px 0px 15px;

            img {
              width: 20px;
              height: 20px;
            }
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 14px 0px 14px;

            .mapPinImg {
              width: 20px;
              height: 24px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .rightSide {
      width: 100%;
      display: flex;
      gap: 96px;
      margin-bottom: 45px;
      height: auto;

      .selectorWrapper {
        margin-top: 10px;

        .selector {
          max-width: 205px;
          min-height: 37px;
          background: #f5f5f5;
          margin-bottom: 5px;
          box-shadow: none;

          &:before {
            display: none;
          }

          .summary {
            border: 1px solid rgb(206, 214, 221);
            border-radius: 8px;
            min-height: 19px;
            height: 100%;
            width: 205px;

            div {
              margin: 5px;

              p {
                font-family: 'DM Sans';
                font-style: normal;
                color: #323e51;
                margin: 0;
                font-weight: 500;
              }
            }
          }

          .summary:focus {
            border: 1px solid rgba(11, 52, 86, 0.5) !important;
          }

          .details {
            background-color: #ffffff !important;
            margin-top: 10px;
            padding: 18px 29px;
            border-radius: 5px;
            align-items: center;
            row-gap: 30px;
            display: flex;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);

            .item {
              display: flex;
              flex-direction: column;
              margin-bottom: 18px;
              font-family: 'DM Sans';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.04em;
              color: #0b3456;
            }

            .radioGroup {
              border-radius: 5px;

              .label {
                span {
                  font-size: 14px;
                }

                .radioBtn {
                  color: #a2b2bf;

                  svg {
                    width: 15px;
                    height: 15px;
                  }
                }

                .checked {
                  color: #0b3456 !important;
                }
              }
            }

            :hover {
              background-color: transparent;
            }
          }

          .priceDetails {
            background-color: #ffffff !important;
            margin-top: 10px;
            padding: 24px 7px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 30px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);

            .accardionContent {
              display: flex;
              height: 26px;
              column-gap: 5px;

              .selectCurrency {
                border: 1px solid #0b3456;
                border-radius: 5px;
                height: 26px;
                width: 55px;
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
                letter-spacing: 0.04em;
                color: #0b3456;

                .paper {
                  background-color: red;
                  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
                }

                div {
                  margin: 0 5px 0 5px;
                  padding: 0;
                  padding-right: 13px;
                  display: flex;
                  align-items: center;
                  box-shadow: none !important;
                }

                em {
                  font-style: normal !important;
                  color: #0b3456 !important;
                }

                svg {
                  right: 2px;
                  width: 18px;
                  color: #0b3456;
                }

                input {
                  font-style: normal !important;
                }
              }

              .item {
                font-size: 11px;
                align-items: center;
                display: flex;
                font-weight: 700;
                color: #0b3456;
              }

              .selectCurrency:active {
                border: 1px solid rgba(11, 52, 86, 0.5) !important;
              }

              .priceInput {
                border: 0.7px solid rgba(37, 37, 37, 0.65);
                border-radius: 5px;
                width: 55px;
                height: 26px;
                font-size: 12px;

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                  transition: background-color 5000s ease-in-out 0s;
                }

                input[type='number'] {
                  -moz-appearance: textfield;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                input {
                  padding: 0 14px;
                  height: 26px;
                }

                input::placeholder {
                  font-size: 12px;
                  color: balck !important;
                }
              }
            }

            .applyBtn {
              text-transform: none;
              color: #ffffff;
              width: 124px;
              height: 28px;
              background: linear-gradient(90.45deg, #0b3456, #083b64 104.65%);
              border-radius: 10px;

              &:disabled {
                background: linear-gradient(
                  90.45deg,
                  rgba(11, 46, 76, 0.55) 0.47%,
                  rgba(16, 79, 133, 0.429) 104.65%
                );
              }
            }

            .applyBtn:hover {
              background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
            }

            :hover {
              background-color: transparent;
            }
          }
        }
      }

      .cardWrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 86px;
        max-width: 1170px;
        width: 100%;

        .itemsCount {
          display: flex;
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          margin-top: 37px;
          margin-left: 10px;
          cursor: pointer;

          img {
            margin-right: 14px;
          }
        }

        .loaderBox {
          max-width: 1170px;
          width: 100%;
          box-sizing: border-box;
          padding-top: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .results {
          display: flex;
          max-width: 1170px;
          flex-wrap: wrap;
          align-items: flex-start;
          column-gap: 13px;
          row-gap: 26px;
          margin-top: 19px;

          .card {
            max-width: 204px;
            height: 332px;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            border-radius: 9px;
            overflow: hidden;
            position: relative;
            padding-bottom: 10px;

            .showMoreWindow {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: #305c76f2;
              transform: translateY(354px);
              transition: all 0.4s;
              cursor: pointer;

              .showMoreText {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 16px;
                padding: 8px;
                cursor: pointer;
                font-family: 'DM Sans';
              }
            }

            .img {
              width: 184px;
              height: 184px;
              margin: 10px 11px 5px 10px;
            }

            .name {
              margin: 3px 11px;
              cursor: default;

              .showMore {
                cursor: pointer;
              }

              img {
                width: 19px;
              }
            }

            .name {
              font-family: 'DM Sans';
              font-style: normal;
              font-weight: 600;
            }

            .iconBox {
              display: flex;
              align-items: center;
              column-gap: 4px;
              margin-left: 10px;

              .usdcLogo {
                width: 15px;
              }

              .solPrice {
                font-weight: 700;
                color: #161616;
                font-family: DM Sans;
              }

              .usdPrice {
                color: #161616;
                font-weight: 400;
                font-family: DM Sans;
                opacity: 0.61;
              }
            }

            .btn {
              margin-top: 23px;
              width: 100%;
              background: #0b3456;
              border-radius: 0px 0px 9px 9px;
              text-transform: none !important;
              color: white;
              position: absolute;
              bottom: 0;
              font-family: 'DM Sans';
            }

            .btn:hover {
              background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
            }

            .activeMint {
              margin-top: 23px;
              width: 100%;
              border-radius: 0px 0px 9px 9px;
              text-transform: none !important;
              color: white;
            }

            &:hover {
              .showMoreWindow {
                transform: translateY(0);
              }
            }
          }
        }

        .lastElement {
          margin-top: 150px;
          width: 100%;
        }
      }

      .showLessIcon {
        text-align: center;
        margin-bottom: 49px;
        position: relative;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    background-color: #ffffff;

    .container {
      flex-direction: column;
      width: 95%;
      margin: auto;
      padding-top: 60px;

      .leftSide {
        width: 100%;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 22px;
          margin: 30px 0;
        }

        .searchBar {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .searchWrapper {
            .search {
              max-width: 254px;
              width: 100%;
              height: 34px;

              .searchicon {
                padding: 0;
              }

              input {
                padding: 6px;
                font-size: 13px;
                font-style: italic;
              }

              .searchButton {
                width: 32px !important;
                align-self: center;
                height: 32px;
                background: #0b3456;
                border: 1px solid #0b3456;
                border-radius: 33px;
                color: #ffffff;

                svg {
                  width: 20px;
                }
              }
            }
          }

          .selectSection {
            height: 28px;
            display: flex;
            border: 1px solid #d6d7d9;
            border-radius: 5px;
            margin-left: auto;
            cursor: pointer;

            .chartImg {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ced6dd;
              padding: 0px 10px 0px 10px;

              img {
                width: 15px;
                height: 15px;
              }
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 10px 0px 10px;

              .mapPinImg {
                width: 15px;
                height: 14px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .rightSide {
        flex-direction: column;
        flex-wrap: wrap;

        .selectorWrapper {
          margin: 0;

          .selector {
            max-width: 205px;
            min-height: 37px;
            background: #f5f5f5;

            .summary {
              border: 1px solid rgb(206, 214, 221);
              border-radius: 8px;

              min-height: 37px;

              p {
                margin: 0;
              }
            }

            .summary:focus {
              border: 1px solid rgba(11, 52, 86, 0.5) !important;
            }

            .details {
              background-color: #ffffff !important;
              margin-top: 10px;

              .item {
                display: flex;
                flex-direction: column;

                .label {
                  align-self: flex-start;
                  font-size: 12px;
                  line-height: 16px;
                  letter-spacing: 0.04em;
                  color: rgba(22, 22, 22, 0.42);
                }
              }

              .buttonBox {
                display: flex;
                justify-content: space-between;
                margin-top: 21px;

                .btn {
                  text-transform: none;
                  color: rgba(22, 22, 22, 0.42);
                }
              }

              .radioGroup {
                font-size: 14px;
                border-radius: 5px;

                .radioBtn {
                  color: #a2b2bf;
                  padding: 10px;
                }

                .checked {
                  color: #0b3456 !important;
                }
              }

              :hover {
                background-color: transparent;
              }

              .priceInput {
                border: 0.7px solid rgba(37, 37, 37, 0.65);
                border-radius: 5px;
                height: 36px;
              }
            }

            .priceDetails {
              background-color: #ffffff !important;
              margin-top: 10px;
              padding: 24px 7px;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 30px;

              .accardionContent {
                display: flex;
                height: 26px;
                column-gap: 5px;

                .selectCurrency {
                  border: 1px solid #0b3456;
                  font-size: 11px;
                  border-radius: 5px;
                  height: 28px;

                  div {
                    margin: 0 5px 0 5px;
                    padding: 0;
                    padding-right: 13px;
                  }

                  svg {
                    right: 2px;
                  }
                }

                .item {
                  font-size: 11px;
                  align-items: center;
                  color: #0b3456;
                  display: flex;
                  font-weight: 700;
                }

                .selectCurrency:active {
                  border: 1px solid rgba(11, 52, 86, 0.5) !important;
                }

                .priceInput {
                  border: 0.7px solid rgba(37, 37, 37, 0.65);
                  border-radius: 5px;
                  width: 55px;
                  height: 26px;
                  font-size: 12px;

                  input:-webkit-autofill,
                  input:-webkit-autofill:hover,
                  input:-webkit-autofill:focus,
                  input:-webkit-autofill:active {
                    transition: background-color 5000s ease-in-out 0s;
                  }

                  input {
                    padding: 0 14px;
                    height: 26px;
                  }

                  input::placeholder {
                    font-size: 12px;
                    color: balck !important;
                  }
                }
              }

              .applyBtn {
                text-transform: none;
                color: #ffffff;
                width: 124px;
                height: 28px;
                background: linear-gradient(90.45deg, #0b3456, #083b64 104.65%);
                border-radius: 10px;

                &:disabled {
                  background: linear-gradient(
                    90.45deg,
                    rgba(11, 46, 76, 0.55) 0.47%,
                    rgba(16, 79, 133, 0.429) 104.65%
                  );
                }
              }

              .applyBtn:hover {
                background: linear-gradient(
                  90.45deg,
                  #0b2e4c 0.47%,
                  rgba(16, 79, 133, 0.78) 104.65%
                );
              }

              :hover {
                background-color: transparent;
              }
            }
          }
        }

        .cardWrapper {
          display: flex;
          flex-direction: column;

          .items {
            display: flex;
            justify-content: space-between;

            .itemsCount {
              font-size: 12px;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                margin-right: 10px;
              }
            }

            .filter {
              margin-top: 37px;
              margin-left: auto;
              width: 29.24px;
              height: 28px;
              background: #ced6dd;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            }

            .selectorWrapper {
              position: absolute;
              top: 274px;
              right: 11px;
              background: #ffffff;
              width: 243px;
              z-index: 100;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 14px 0px 50px 0px;
              border-radius: 8px;
              box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.34);

              .closebtn {
                align-self: flex-end;
                margin-bottom: 40px;
              }

              .selector {
                max-width: 205px;
                width: 100%;
                min-height: 37px;
                background: #ffffff;
                margin-bottom: 5px;
                box-shadow: none;

                .summary {
                  border: 1px solid rgb(206, 214, 221);
                  border-radius: 8px;
                  min-height: 19px;
                  height: 100%;
                  width: 205px;

                  div {
                    margin: 5px;

                    p {
                      font-family: 'DM Sans';
                      font-style: normal;
                      color: #323e51;
                      margin: 0;
                      font-weight: 600;
                    }
                  }
                }

                .summary:focus {
                  border: 1px solid rgba(11, 52, 86, 0.5) !important;
                }

                .details {
                  background-color: #ffffff !important;
                  margin-top: 10px;
                  padding: 18px 29px;
                  border-radius: 5px;
                  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);

                  .item {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 18px;
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.04em;
                  }

                  .radioGroup {
                    border-radius: 5px;

                    .label {
                      span {
                        font-size: 14px;
                      }

                      .radioBtn {
                        color: #a2b2bf;

                        svg {
                          width: 15px;
                          height: 15px;
                        }
                      }

                      .checked {
                        color: #0b3456 !important;
                      }
                    }
                  }

                  :hover {
                    background-color: transparent;
                  }

                  .priceInput {
                    border: 0.7px solid rgba(37, 37, 37, 0.65);
                    border-radius: 5px;
                    height: 36px;
                    font-size: 12px;

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                      transition: background-color 5000s ease-in-out 0s;
                    }

                    input {
                      padding: 6px 14px;
                    }

                    input::placeholder {
                      font-size: 12px;
                      color: rgba(22, 22, 22, 0.42);
                    }
                  }
                }

                .priceDetails {
                  background-color: #ffffff !important;
                  margin-top: 10px;
                  margin-bottom: -20px;
                  padding: 24px 7px;
                  border-radius: 5px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  row-gap: 30px;
                  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);

                  .accardionContent {
                    display: flex;
                    height: 26px;
                    column-gap: 5px;

                    .selectCurrency {
                      border: 1px solid #0b3456;
                      font-size: 11px;
                      border-radius: 5px;
                      height: 28px;

                      div {
                        margin: 0 5px 0 5px;
                        padding: 0;
                        padding-right: 13px;
                      }

                      svg {
                        right: 2px;
                      }

                      .item {
                        font-size: 11px;
                        align-items: center;
                        color: #0b3456;
                        display: flex;
                        font-weight: 700;
                      }

                      .selectCurrency:active {
                        border: 1px solid rgba(11, 52, 86, 0.5) !important;
                      }

                      .priceInput {
                        border: 0.7px solid rgba(37, 37, 37, 0.65);
                        border-radius: 5px;
                        width: 55px;
                        height: 26px;
                        font-size: 12px;

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        input:-webkit-autofill:active {
                          transition: background-color 5000s ease-in-out 0s;
                        }

                        input {
                          padding: 0 14px;
                          height: 26px;
                        }

                        input::placeholder {
                          font-size: 12px;
                          color: balck !important;
                        }
                      }
                    }

                    .applyBtn {
                      text-transform: none;
                      color: #ffffff;
                      width: 124px;
                      height: 28px;
                      background: linear-gradient(90.45deg, #0b3456, #083b64 104.65%);
                      border-radius: 10px;

                      &:disabled {
                        background: linear-gradient(
                          90.45deg,
                          rgba(11, 46, 76, 0.55) 0.47%,
                          rgba(16, 79, 133, 0.429) 104.65%
                        );
                      }

                      border-radius: 10px;
                    }

                    .applyBtn:hover {
                      background: linear-gradient(
                        90.45deg,
                        #0b2e4c 0.47%,
                        rgba(16, 79, 133, 0.78) 104.65%
                      );
                    }

                    :hover {
                      background-color: transparent;
                    }
                  }

                  .applyBtn {
                    text-transform: none;
                    color: #ffffff;
                    width: 124px;
                    height: 28px;

                    background: linear-gradient(90.45deg, #0b3456, #083b64 104.65%);
                    border-radius: 10px;

                    &:disabled {
                      background: linear-gradient(
                        90.45deg,
                        rgba(11, 46, 76, 0.55) 0.47%,
                        rgba(16, 79, 133, 0.429) 104.65%
                      );
                    }

                    border-radius: 10px;
                  }

                  .applyBtn:hover {
                    background: linear-gradient(
                      90.45deg,
                      #0b2e4c 0.47%,
                      rgba(16, 79, 133, 0.78) 104.65%
                    );
                  }

                  :hover {
                    background-color: transparent;
                  }
                }
              }
            }
          }

          .filteredByBox {
            max-width: 164px;
            width: 100%;
            display: flex;
            background: #f4f4f4;
            border-radius: 35px;
            justify-content: space-between;
            align-items: center;
            padding: 8px 17px;
            margin-top: 17px;

            p {
              font-family: 'DM Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.02em;
              color: #323e51;
            }

            img {
              width: 9px !important;
              color: #6d6d6d;
            }
          }

          .loaderBox {
            width: 100vw;
          }

          .results {
            display: flex;
            column-gap: 4px;
            justify-content: space-between;
            row-gap: 21px;

            .card {
              max-width: 115px;
              height: 100%;
              border: 1px solid rgba(0, 0, 0, 0.1);
              position: relative;

              .img {
                width: 90px;
                height: 84px;
                margin: 5px;
                border-radius: 4px;
                align-self: center;
              }

              .name {
                font-size: 14px;
              }

              .iconBox {
                font-size: 11px;
                align-items: center;

                img {
                  width: 14px;
                }
              }

              p:last-child {
                margin-bottom: 0;
              }

              .btn {
                height: 28px;
                margin-top: 8px;
              }

              .activeMint {
                margin-top: 15px;
                width: 100%;
                background: #0b3456;
                border-radius: 0px 0px 9px 9px;
                text-transform: none !important;
                color: white;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 590px) {
    .container {
      .rightSide {
        .cardWrapper {
          .results {
            justify-content: flex-start;
            .card {
              height: 200px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .container {
      .rightSide {
        .cardWrapper {
          .results {
            column-gap: 0;

            .card {
              max-width: 100px;

              .name {
                font-size: 12px;
              }

              .iconBox {
                font-size: 10px;

                img {
                  width: 13px;
                }

                .usdPrice {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    .container {
      .rightSide {
        .cardWrapper {
          .results {
            column-gap: 5px;
            justify-content: space-between;

            .card {
              max-width: 95px;

              .name {
                font-size: 12px;
              }

              .iconBox {
                font-size: 10px;

                img {
                  width: 13px;
                }

                .usdPrice {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 315px) {
    .container {
      .rightSide {
        .cardWrapper {
          .results {
            column-gap: 5px;
            justify-content: space-between;

            .card {
              max-width: 90px;

              .name {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 300px) {
    .container {
      .rightSide {
        .cardWrapper {
          .results {
            column-gap: 5px;
            justify-content: space-between;

            .card {
              max-width: 120px;

              .name {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 260px) {
    .container {
      .rightSide {
        .cardWrapper {
          .results {
            display: flex;
            column-gap: 4px;
            justify-content: center;
            row-gap: 21px;

            .card {
              max-width: 115px;
              height: 100%;
              border: 1px solid rgba(0, 0, 0, 0.1);

              .name {
                font-size: 14px;
              }

              .iconBox {
                font-size: 11px;
              }

              .btn {
                height: 28px;
                margin-top: 8px;
              }

              .activeMint {
                margin-top: 15px;
                width: 100%;
                background: #0b3456;
                border-radius: 0px 0px 9px 9px;
                text-transform: none !important;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.cardCoordinates {
  margin-left: 10px;
  display: flex;
  align-items: center;
  column-gap: 4px;

  .coordinates {
    color: #2c2c2c;
    font-family: DM Sans;
    font-weight: 500;
  }

  img {
    max-width: 18px;
  }
}
