.wrapper {
  display: flex;
  flex-direction: column;
  padding: 109px 0 0 0 !important;
  max-width: 100% !important;
  .container {
    .titleWrapper {
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
      max-width: 100% !important;
      text-align: center;
      padding: 55px 0;
      .title {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }
  .mainContainer {
    padding: 78px 10%;
    .start {
      display: flex;
      max-width: 100% !important;
      padding: 0 !important;
      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px;
        .mainIcon {
          height: 273px;
        }
        .underIcon {
          width: 201px;
        }
      }
    }
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 47px;
      padding: 0 !important;
      .inline {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 20px;
        color: #161616;
      }

      @media screen and (max-width: 1600px) {
        margin-left: 30px;
      }
    }
  }
  .wrapperData {
    display: flex;
    margin-top: 133px;
    height: 1306px;
    .content {
      background: #f7f7f7;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 150px 20px 70px 20px;
      width: 30%;
      .minWrapper {
        display: flex;
        flex-direction: column;
        gap: 31px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 11px;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #ffffff;
          border-radius: 9px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 60, 134, 0.2);
          border-radius: 9px;
          height: 10px;
        }

        .titles {
          font-family: "DM Sans";
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          cursor: pointer;
        }
        .chosen {
          font-family: "DM Sans";
          font-weight: 400;
          font-size: 15px;
          color: #003c86;
          cursor: pointer;
        }
      }
    }
  }
  .infoWrapper {
    display: flex;
    height: 1306px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.22);
    .info {
      margin: 62px 45px 56px 30px;
      display: flex;
      flex-direction: column;
      gap: 23px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 11px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 9px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 60, 134, 0.2);
        border-radius: 9px;
        height: 10px;
      }

      .title {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 150%;
        color: #003c86;
        margin-bottom: 14px;
      }
      .body {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 172%;
        color: #161616;
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .wrapper {
    .mainContainer {
      padding: 30px 20px;
    }

    .wrapperData {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 72px 0 0 0;
    .container {
      padding: 0px !important;
      .titleWrapper {
        background: #ffffff;
        padding: 0px 0px 31px 0px;
        .title {
          font-size: 22px;
          color: #0b3456;
        }
      }
      .mainContainer {
        padding: 0;
        .start {
          flex-direction: column;
          padding: 0px 23px 0px 23px !important;
          .icon {
            margin-top: 35px;

            .mainIcon {
              height: 163px;
            }
            .underIcon {
              width: 122px;
            }
          }
          .textWrapper {
            margin-left: 0px !important;

            .inline {
              font-size: 18px;
            }
          }
        }
      }
    }
    .wrapperData {
      margin-top: 32px;
      width: 100%;
    }
    .mainContainer {
      padding: 0px;
    }
    .wrapperData {
      height: auto;
    }
  }
}
