.pageContainer {
  display: flex;
  max-width: 1350px;
  width: 100%;
  justify-content: space-between;
  column-gap: 125px;
  flex-wrap: wrap;

  .leftSection {
    max-width: 720px;

    .pageTitle {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      margin-bottom: 19px;
    }

    .pageSubTitle {
      font-family: "DM Sans";
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.02em;
      margin-bottom: 16px;
    }

    .pageText {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 1161px) {
  .pageContainer {
    .rightSection {
      img {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pageContainer {
    .leftSection {
      .pageTitle {
        font-size: 20px;
        margin-bottom: 26px;
      }

      .pageSubTitle {
        font-size: 18px;
        margin-bottom: 9px;
      }

      .pageText {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        margin-bottom: 44px;
      }
    }
    .rightSection {
      display: none;
    }
  }
}
