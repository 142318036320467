.homeContainer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../assets/images/discoverBackground.svg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  width: 100%;

  @media screen and (max-width: 600px) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(../../assets/images/discoverExgoland.png);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
