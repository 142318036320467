.pageContainer {
  display: flex;
  column-gap: 29px;
  flex-wrap: wrap;

  .leftSection {
    max-width: 875px;

    .pageTitle {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      margin-bottom: 25px;
    }

    .pageDescription {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 38px;
      span {
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 1161px) {
  .pageContainer {
    .leftSection {
      .pageTitle {
        font-size: 24px;
      }

      .pageDescription {
        font-size: 10px;
      }

      img {
        width: 80%;
      }
    }

    .bottomImg {
      img {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width:600px) {
  .pageContainer {
    .leftSection {
      .pageTitle {
        font-size: 18px;
        margin-bottom: 13px;
      }

      .pageDescription {
        font-size: 16px;
        margin-bottom: 57px;
      }

      img {
        width: 100%;
        margin-bottom: 57px;
      }
  }

  .bottomImg {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
}