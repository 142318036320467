* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}

html {
  overflow-x: hidden;
  //font-size: 62.5%;
  //font-size-adjust: 100;
  //-ms-text-size-adjust: 100%;
  //-webkit-text-size-adjust: 100%;
}