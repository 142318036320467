.mapContainerTitle {
  font-size: 36px !important;
  font-weight: 600 !important;
  margin-bottom: 35px !important;
  @media (max-width: 600px) {
    font-size: 20px !important;
  }
}
.mapContainerSecondTitle {
  font-size: 27px !important;
  font-weight: 500 !important;
  margin-bottom: 30px !important;
  @media (max-width: 600px) {
    font-size: 18px !important;
  }
}
.mapContainerSecondContant {
  max-width: 944px;
  margin-bottom: 60px;
  margin-top: 45px;
  font-size: 16px;
  font-family: 'DM Sans';
  @media (max-width: 600px) {
    margin-top: 17px;
  }
}
.mapContainer2D {
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .firstBox2d {
    margin-top: 10px;
    max-width: 720px;
    margin-right: 30px;
  }
  .secondBox2d {
    max-width: 595px;
  }
  .colors2d {
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
    .listItem2d {
      position: relative;

      font-size: 16px;
      font-weight: 400;
      .listItemMarker2d {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        bottom: 50%;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
      .colorItem {
        width: 65px;
        height: 31px;
        border-radius: 5px;
        margin-left: 30px;
      }
    }
  }
}
