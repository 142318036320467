.comparisonContainer {
  .bottomTitle {
    font-family: "DM Sans";
    font-weight: 550;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin-bottom: 16px;
  }

  .bottomText {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    max-width: 720px;
  }

  .compContainer {
    width: 100%;
    max-width: 1230px;
    margin-bottom: 37px;

    .title {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
    }

    .featuresCell,
    .cell {
      border: 1px solid rgba(224, 224, 224, 1);
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #010101;
      padding: 0;
      height: 55px;
    }

    .featuresCell {
      font-weight: 600;
      padding-left: 21px;
    }
  }
}

@media screen and (max-width: 1660px) {
  .comparisonContainer {
    .compContainer {
      width: 95%;
    }
  }
}

@media screen and (max-width: 1161px) {
  .comparisonContainer {
    width: 100%;
    box-sizing: border-box;
    padding-right: 50px;

    .compContainer {
      width: 100%;
      .title,
      .cell,
      .featuresCell {
        font-size: 10px;
        padding: 5px 10px;
        height: auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .comparisonContainer {
    width: 100%;
    .bottomTitle {
      margin-top: 37px;
      font-size: 18px;
      font-weight: 600;
    }

    .compContainer {
      width: 335px;
      .table {
        width: 100%;
      }
    }
  }
}
.mobileTableBox {
  width: 100%;
  img {
    width: 100%;
    height: 525px;
  }
}
