.container {
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
  padding-top: 109px;

  .content {
    background-image: url("../../assets/images/blognews/wallpaper.png");
    justify-content: center;
    row-gap: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    width: 100%;

    .headerContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      .logo {
        width: 94px;
        height: 96px;
      }

      .title {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 150%;
        color: #000000;
      }

      .subTitle {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 7px;
        text-transform: uppercase;
        color: #555761;
        margin-top: 20px;
      }
    }
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 92px;

  .dataDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 765px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #292929;
    border-radius: 48px;
    justify-content: space-between;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }

  .arrow {
    margin-right: 16px;
  }

  .dataDiv:hover {
    box-shadow: 4px 4px 0px #292929;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-left: 8px;
  }

  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #161616;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding-top: 77px;

    .content {
      background-image: url("../../assets/images/blognews/blogMobilebackround.svg");
      
      .headerContainer {
        gap: 0;

        .logo {
          width: 70px;
          height: 70px;
        }

        .title,
        .subTitle {
          font-size: 20px;
        }

        .subTitle {
          margin-top: 20px;
        }
      }
    }
  }

  .dataContainer {
    margin-bottom: 0;
    width: 80%;

    .dataDiv {
      width: 100%;
      height: 48px;
    }
  }
}
