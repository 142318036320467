.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  max-width: 100% !important;
  max-height: 100vh;
  height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;

  .container {
    padding-top: 109px;
    width: 100%;
    margin: 0 auto;

    .popUpModalContainer {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-30%, 10%);
      max-width: 400px;
      min-width: 300px;
      z-index: 2;
    }

    .containerHeader {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 58px;
      padding: 0 20px;

      .title {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #08365c;
        width: 374px;
      }
    }

    .bottomSection {
      display: flex;
      width: 100%;
      box-sizing: border-box;

      .mapWrapper {
        width: 100%;
        position: relative;
      }
    }
  }

  @media screen and (max-width: 1056px) {
    .container {
      .bottomSection {
        position: relative;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .container {
      padding-top: 140px;
    }
  }

  @media screen and (max-width: 600px) {
    background: white;

    .container {
      padding-top: 70px;

      .popUpModalContainer {
        position: absolute;
        top: 10%;
        right: 50%;
        transform: translate(50%);
        width: 90%;
        z-index: 2;
      }
      .containerHeader {
        box-sizing: border-box;
        width: 100%;
        padding: 0px 20px 0px 20px;
        margin-bottom: 37px;

        .secondSection {
          .selectSection {
            height: 28px;

            a {
              padding: 0px 10px 0px 10px;

              .chartImg {
                width: 15px;
                height: 15px;
              }
            }

            .mapPinImg {
              padding: 0px 10px 0px 10px;

              img {
                width: 15px;
                height: 14px;
              }
            }
          }
        }

        .title {
          width: auto;
          font-size: 22px;
        }

        .input {
          padding: 8px;

          input {
            padding: 0;
            padding-left: 30px;
          }
        }

        .selector {
          height: 40px;

          .menuItem {
            border-bottom: 1px solid rgba(30, 62, 90, 0.41);
          }
        }
      }

      .bottomSection {
        flex-direction: column;

        .rightSide {
          max-width: 100%;

          .btn {
            max-width: 137px;
          }
        }

        .mapWrapper {
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
