.container {
  position: relative;
  max-width: 680px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: none;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px 20px 80px;

  .loaderBackground {
    background: rgba(0, 0, 0, 0.34);
    position: fixed;
    z-index: 112;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    p {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 150%;
      color: #042037;
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .processText {
      text-align: center;
    }
  }

  .inputContainer {
    width: 100%;
    display: flex;
    margin-top: 40px;
    .combinedLandNameInputContainer {
      width: 58%;
      position: relative;
      margin-right: 10px;
      .placeholderDefault {
        position: absolute;
        display: flex;
        top: 8px;
        left: 27px;
        font-size: 14px;
        color: #78777793;
        pointer-events: none;
        .red {
          color: red;
        }
      }
      .combinedLandNameInput {
        border: 0.7px solid #0b345680;
        border-radius: 33px;
        width: 100%;
        div {
          &::before {
            border-bottom: none;
          }
          &::after {
            border-bottom: none;
          }
          input {
            box-sizing: border-box;
            padding-left: 26px;
            height: 37px;
            &::placeholder {
              font-size: 14px;
            }
          }
        }
      }
    }

    .royaltyInputContainer {
      width: 36%;
      position: relative;
      display: flex;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 25px;
    .closeButton {
      width: 25px;
      height: 25px;
    }
  }

  .logos {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .uploadedImg {
      width: 100px;
      height: 100px;
    }

    .about {
      margin-top: 10px;
      font-weight: 700;
      font-size: 22px;
    }
  }

  .landsInfo {
    width: 100%;
    height: 9rem;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    overflow-y: auto;
    gap: 7px;
    margin-top: 10px;
    .landItem {
      width: 100%;
      height: 100px;

      .landName {
        width: 100%;
        height: 45px;
        font-weight: 700;
        font-size: 16px;
        border-bottom: 1px solid rgba(22, 22, 22, 0.2);
        display: flex;
        align-items: center;
        margin-top: 20px;
      }

      .landLocation {
        width: 100%;
        height: 45px;
        border-bottom: 1px solid rgba(22, 22, 22, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .location {
          color: rgba(22, 22, 22, 0.7);
        }

        .coordinatesBox {
          display: flex;
          color: #2c2c2c;
          align-items: center;
          column-gap: 3px;

          img {
            width: 16px;
            height: 16px;
          }

          .coordinatesText {
            display: flex;
            font-weight: 600;
            cursor: default;
          }
        }
      }
    }
  }

  .buttons {
    width: 285px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .closeButton {
      width: 120px;
      height: 45px;
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
      border-radius: 10px;
      color: #ffffff;
      font-size: 11px;
    }

    .combineButton {
      width: 120px;
      height: 45px;
      background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
      border-radius: 10px;
      color: #ffffff;
      font-size: 11px;
      &:disabled {
        background: linear-gradient(
          270.15deg,
          rgba(9, 52, 88, 0.55) -3.93%,
          rgba(13, 41, 66, 0.55) 99.82%
        );
      }
    }
  }
}

@media screen and(max-height: 700px) {
  .container {
    padding: 40px 20px 20px 20px;
  }
}

@media screen and(max-width: 600px) {
  .container {
    padding: 80px 20px 20px 20px;
    .inputContainer {
      flex-direction: column;
      .combinedLandNameInputContainer {
        width: 100%;
      }
      .royaltyInputContainer {
        margin-top: 10px;
        width: 94%;
      }
    }

    .close {
      right: 10px;
      top: 15px;
    }

    .logos {
      height: auto;
      .uploadedImg {
        width: 70px;
        height: 70px;
      }
      .about {
        font-size: 16px;
      }
    }

    .buttons {
      width: 255px;
    }
  }
}
