.container {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/images/about_us/data-bg.png");
  background-size: cover;
  padding-top: 109px;

  .header {
    width: 100%;
    height: 164px;
    background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    h2 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      color: #ffffff;
    }
  }

  .whoWeAreWrapper {
    padding-top: 60px;
    padding-bottom: 78px;
    max-width: 1430px;
    width: 100%;
    margin: 0 auto;

    align-items: center;

    .whoWeAreContainer {
      display: flex;
      flex-direction: column;
      text-align: start;

      .whoWeAre {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #0c4374;
        text-align: start;
        margin-bottom: 25px;
      }

      .underline {
        text-align: start;

        img {
          height: 9;
          align-items: "start";
        }
      }
    }
  }

  .dataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 127px;
    max-width: 1430px;
    width: 100%;

    .textContainer {
      max-width: 491px;
      width: 100%;

      .text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 261%;
        letter-spacing: 0.02em;
        color: #5c5c5c;
        text-align: justify;
        max-width: 491px;
      }
    }
  }
}

.partnersWrapper {
  padding-top: 70px;
}

@media screen and (max-width: 1500px) {
  .container {
    .dataContainer {
      flex-wrap: wrap;
      width: 90%;
      .imgContainer {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .dataContainer {
      width: 90%;
      flex-direction: column;
      padding-bottom: 60px;

      .imgContainer {
        img {
          width: 100%;
        }
      }

      .textContainer {
        margin-bottom: 20px;

        .text {
          font-size: 16px;
        }
      }
    }

    .whoWeAreWrapper {
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0;

      .whoWeAreContainer {
        width: 90%;
        margin: auto;

        .whoWeAre {
          font-size: 20px;
          margin-top: 30px;
          margin-bottom: 0;
        }

        .underline {
          img {
            width: 50px;
          }
        }
      }
    }
  }
}
