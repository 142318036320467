.landContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;
}

.landSection1 {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  margin-bottom: 129px;
  padding: 93px 0 107px;
  position: relative;
}

.landColumn1Hidden {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.landColumn1 {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  animation: slide-in-left 1s ease-out 0.1s both;

  @keyframes slide-in-left {
    0% {
      transform: translateX(-700px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  p {
    // width: 359px;
    height: 36px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #242424;
  }
}

.buyYourLand {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  margin-bottom: 12px;
  max-width: 298px;
}

.oppRow1,
.oppRow2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    width: 215px;
    height: 24px;
    left: 273px;
    top: 1924px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #242424;
  }
}

.oppRow2 {
  width: 100%;
  justify-content: center;
}

.contentSection {
  max-width: 460px;
  text-align: start;
  width: 100%;

  .subTitle {
    margin-bottom: 60px;
  }
}

.oppColumn {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 4px solid #093458;

  p {
    width: 215px;
    height: 24px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #242424;
    margin-bottom: 12px;
  }
}

.buyDiscoverBtns {
  margin-top: 70px;
  display: flex;
  gap: 14px;
  flex-direction: row;
}

.buyNow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 50px;
  box-sizing: border-box;
  background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
  border-radius: 10px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  cursor: pointer;
  transition: background-position 0.9s ease;
  text-decoration: none;
}

.buyNow:hover {
  background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
}

.discoverMap {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 190px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  border: 2px solid rgba(12, 42, 69, 0.7);
  color: #0c2a45;
  cursor: pointer;
  text-decoration: none;
}

.discoverMap:hover {
  background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
  color: #ffffff;
}

.landColumn2 {
  margin-top: 100px;
  height: 100%;
  width: 73%;

  .landTop {
    position: relative;
    width: 70%;
    z-index: 2;

    &.hidden {
      visibility: hidden;
    }

    &.animate {
      animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @keyframes slide-in-top {
      0% {
        transform: translateY(-400px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .landBot {
    width: 100%;
    margin-top: -200px;

    &.hidden {
      visibility: hidden;
    }

    &.animate {
      animation: slide-in-bot 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @keyframes slide-in-bot {
      0% {
        transform: translateY(400px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-family: "DM Sans" !important;
    font-weight: 700 !important;
    font-size: 35px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.08em;
    color: #242424;
    margin-bottom: 107px;
  }

  .featuresSection {
    display: "flex";
    max-width: 1440px;
    justify-content: space-between;
    width: 100%;
    height: 391px;
    margin-bottom: 40px;

    &.animate {
      animation: scale-in-center 1.6s cubic-bezier(0.68, 1.15, 0.265, 1.55) both;
    }

    &.hidden {
      visibility: hidden;
    }

    @keyframes scale-in-center {
      0% {
        transform: scale(0);
        opacity: 1;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    .featureItemSection {
      max-width: 299px;
      width: 100%;
      cursor: pointer;

      &:hover {
        .featureItem {
          display: none;
        }

        .featureHoverItem {
          display: flex;
        }
      }

      .featureItem {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .iconSection {
          width: 115px;
          height: 115px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
          margin-bottom: 20px;
        }

        .featureTitle {
          font-family: "DM Sans";
          font-weight: 700;
          font-size: 18px;
          line-height: 150%;
          letter-spacing: 0.05em;
          color: #161616;
        }
      }

      .featureHoverItem {
        background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
        border-radius: 23px;
        padding: 29px 29px 35px;
        display: "none ";
        flex-direction: column;
        animation-name: fadeIn;
        animation-duration: 0.7s;

        @keyframes fadeIn {
          from {
            opacity: 0.7;
          }

          to {
            opacity: 1;
          }
        }

        .featureDescription {
          font-family: "DM Sans";
          font-weight: 500;
          font-size: 18px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.05em;
          color: #ffffff;
          max-width: 240px;
        }

        .featureHoverTitle {
          font-family: "DM Sans";
          font-weight: 700;
          font-size: 22px;
          line-height: 150%;
          letter-spacing: 0.05em;
          color: #ffffff;
          text-align: center;
          margin-bottom: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .featuresSection {
    flex-wrap: wrap;
    row-gap: 40px;
    height: 600px !important;
    align-content: space-between;
    justify-content: space-around !important;
  }
}

@media screen and(max-width:561px) {
  .featuresSection {
    height: auto !important;
  }

  .oppRow1,
  .oppRow2 {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .buyDiscoverBtns {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and(max-width: 1200px) {
  .landContainer {
    .landSection1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 30px;
      margin-bottom: 0;
      padding-bottom: 50px;

      .landColumn1 {
        width: 100%;
      }

      .contentSection {
        text-align: center !important;

        .buyYourLand {
          font-size: 24px;
        }

        .subTitle {
          width: 100%;
          font-size: 18px;
          margin-bottom: 0;
        }

        .opportunities {
          width: 100%;
          margin-bottom: 50px;

          .oppRow1,
          .oppRow2 {
            .oppColumn {
              width: 288px;
            }
          }
        }

        .buyDiscoverBtns {
          width: 60%;
          margin: auto;

          .buyNow {
            width: 100%;
          }

          .discoverMap {
            width: 100%;
          }
        }
      }
    }
  }
  .featuresContainer {
    .title {
      margin-bottom: 57px;
    }

    .featuresSection {
      height: auto !important;

      .featureItemSection {
        .featureItem {
          .iconSection {
            width: 94px;
            height: 94px;
            img {
              width: 36px;
              height: 36px;
            }
          }
        }
      }

      .featureHoverItem {
        .featureHoverTitle {
          font-size: 18px !important ;
        }

        .featureDescription {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .landSection1 {
    // flex-wrap: wrap;
    // justify-content: center;
    justify-content: space-between;

    .landColumn2 {
      width: 100%;
    }
  }
}
