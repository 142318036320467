.button {
  border-radius: 10px;
  padding: 13px 25px;
  cursor: pointer;
  max-width: 137px;
  display: flex;
  justify-content: center;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
}

.darkBtn {
  background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
  span {
    color: white;
  }
}
.darkBtn:hover {
  background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
}

.lightBtn {
  border: 1.5px solid rgba(10, 49, 81, 0.7);
  background-color: #ffffff;
  span {
    color: #0a3153;
  }
}
.lightBtn:hover {
  background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
  span {
    color: #ffffff;
  }
}

@media screen and (max-width: 600px) {
  .button {
    max-width: 152px;
    width: 100%;
  }
}
