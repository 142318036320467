.modalContainer {
  width: 100%;
  min-width: 400px;
  background-color: #040f35;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 90px 25px 38px 25px;
  display: flex;
  flex-direction: column;
  .closeIconBox {
    fill: #ffffff;
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    .closeIcon {
      fill: #ffffff;
    }
  }
  .mintButtonBox {
    padding: 1px;
    background: linear-gradient(90.06deg, #1d67aa 2.17%, #a3cff4 92.93%);
    border-radius: 9px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: -65px;
    .mintButton {
      width: 100%;
      height: 100%;
      background: #040f35;
      border-radius: 9px;
      color: #ffffff;
    }
  }

  .modalHeader {
    width: 100%;
    display: flex;
    .firstSection {
      width: 100%;
      display: flex;
      .landImg {
        width: 101px;
        height: 101px;
        border-radius: 5px;
      }
      .headerInfoBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #ffffff;
        padding: 0px 10px;
        width: 60%;
        .notMintedText {
          width: 120px;
          margin-bottom: 15px;
        }
        .landName {
          // margin-bottom: auto;
          font-size: 16px;
        }
        .iconBox {
          display: flex;
          align-items: center;

          .text {
            font-size: 14px;
          }
          .mapPin {
            width: 13px;
            height: 16px;
            margin-right: 6px;
            margin-left: 2px;
          }
          .solanaIcon {
            width: 17px;
            height: 17px;
            margin-right: 4px;
          }
        }
      }
    }

    .landStatusBox {
      width: 40%;
      text-align: center;
    }
  }
  .bottomSection {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    align-items: flex-start;
    margin-top: 28px;
    .status {
      background: linear-gradient(90.06deg, #1d67aa 2.17%, #a3cff4 92.93%);
      border-radius: 5px;
      color: #ffffff;
      padding: 2px 17px;
      font-size: 12px;
      font-weight: 600;
      width: 130px;
      // max-width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 28px;
      line-height: 24px;
    }
    .ownerBox {

      .ownerHeader {
        color: #ffffffb2;
        font-size: 12px;
        max-width: 40px;
      }

      .ownerName {
        font-size: 16px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 120px;
        color: white;
      }

      .walletAddress{
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  
  .descriptionBox {
    // margin-top: 10px;

    .descriptionHead {
      color: #ffffffb2 !important;
      font-size: 12px !important;
      max-width: 40px;
    }
    // margin-top: 50px;

    .mintDescription,
    .description {
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      font-family: 'DM Sans';

      .unmintedDescriptionListItem {
        display: flex;
        align-items: center;
        margin-left: 4px;
        font-size: 12px;

        .circle {
          width: 5px;
          height: 5px;
          background-color: #ffffff;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }
    p:nth-of-type(1) {
      color: white;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
    }
    p:nth-of-type(2) {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
    }
  }

  .buttonsSection {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 285px;
    width: 100%;
    align-items: center;
    column-gap: 20px;
    margin: 58px auto 0px;
    .buyButton {
      border-radius: 9px;
      border: 1px solid #1d67aa;
      font-family: 'DM Sans';
      font-weight: 500;
      line-height: 150%;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      max-width: 160px;
      text-transform: none;
      &:hover {
        background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
      }
    }

    .showMoreButton {
      color: white;
      font-family: 'DM Sans';
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      justify-content: center;
      text-decoration: underline;
      text-transform: none;
      max-width: 87px;
      width: 100%;
      padding: 0;
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  .modalContainer {
    padding: 67px 23px 38px 23px;
    // height: 521px;
    min-width: auto;

    .closeIconBox {
      top: 15px;
      right: 15px;
    }
    .mintButtonBox {
      margin-top: 80px;
      margin-bottom: -55px;
      .mintButton {
        width: 100%;
        height: 100%;

        padding: 8px 0px;
      }
    }
    .modalHeader {
      .firstSection {
        column-gap: 10px;
        .landName {
          // margin-bottom: 50px !important;
        }

        .landImg {
          width: 126px;
        }
        .headerInfoBox {
          padding: 0px;
          width: 60%;
          height: 90px;
          .iconBox {
            margin-top: 4px;
          }
        }
      }
      .landStatusBox {
        display: flex;
        flex-direction: column;
        .status {
          margin-bottom: auto;
        }
        .ownerBox {
          margin-bottom: 12px;
        }
      }
    }
  }
}
