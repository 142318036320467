.pageContainer {
  display: flex;
  column-gap: 31px;
  flex-wrap: wrap;
  column-gap: 140px;
  img {
    max-width: 500px;
  }
  .contentSection {
    max-width: 690px;
    width: 100%;

    .title {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      color: #000000;
      margin-bottom: 24px;
    }

    .description {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #000000;
      margin-bottom: 54px;
      &.desc{
        width: 87%;
      }
      span {
        font-weight: 700;
      }
    }

    .paymentMethod {
      display: flex;
      column-gap: 19px;

      .paymentRight {
        .description {
          margin-bottom: 16px;
        }

        ul {
          font-family: "DM Sans";
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.02em;
          color: #000000;
          margin-left: 25px;
          margin-bottom: 24px;
        }
      }

      .methodIcon {
        width: 45px;
        height: 45px;

        &.methodIconAbsolute {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .buyLandIcon {
    display: block;
    width: 60px;
    height: 60px;
    background-color: #43217b14;
    border-radius: 50%;
    position: relative;
  }

  .creditList {
    padding-left: 20px;
  }

  .creditSide {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1161px) {
  .pageContainer {
    .buyLandImg {
      width: 70%;
    }
  }
}

@media screen and (max-width: 600px) {
  .pageContainer {
    .contentSection {
      .paymentMethod {
        column-gap: 23px;
        margin-bottom: 29px;
      }
      .title {
        font-size: 20px;
        margin-bottom: 26px;
      }

      .description {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
  }
}
