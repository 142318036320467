.loaderContainer {
  box-sizing: border-box;
  padding: 5vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.fullScreen {
    position: fixed;
    top: 0;
  }
  z-index: 99;

  .skChase {
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }

  .skChaseDot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;
  }

  .skChaseDotChild {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
  }

  .skChaseDot:nth-child(1) {
    animation-delay: -1.1s;
  }
  .skChaseDot:nth-child(2) {
    animation-delay: -1s;
  }
  .skChaseDot:nth-child(3) {
    animation-delay: -0.9s;
  }
  .skChaseDot:nth-child(4) {
    animation-delay: -0.8s;
  }
  .skChaseDot:nth-child(5) {
    animation-delay: -0.7s;
  }
  .skChaseDot:nth-child(6) {
    animation-delay: -0.6s;
  }
  .skChaseDotChild:nth-child(1):before {
    animation-delay: -1.1s;
  }
  .skChaseDotChild:nth-child(2):before {
    animation-delay: -1s;
  }
  .skChaseDotChild:nth-child(3):before {
    animation-delay: -0.9s;
  }
  .skChaseDotChild:nth-child(4):before {
    animation-delay: -0.8s;
  }
  .skChaseDotChild:nth-child(5):before {
    animation-delay: -0.7s;
  }
  .skChaseDotChild:nth-child(6):before {
    animation-delay: -0.6s;
  }

  @keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot {
    80%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.5);
    }

    100%,
    0% {
      transform: scale(1);
    }
  }
}
