.container {
  .subcolumn2 {
    width: 215px;
    height: 50px;
    background-color: #ffffff !important;
    color: #000000 !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
}

.copy {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-8px, 14px);
}

.close {
  position: absolute;
  top: 26px;
  right: 30px;
  cursor: pointer;
}

.title {
  font-family: 'DM Sans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  letter-spacing: 0.05em !important;
  color: #000000 !important;
  margin-top: 32px !important;
}

.wrapper {
  width: 100%;
  max-width: 360px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 0 0 0 14px !important;
  border-radius: 10px;
  cursor: pointer;

  .element {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.03em;
  }

  .bigLogo {
    width: 37px;
    height: 37px;
  }

  .mobileLogo {
    width: 30px;
    height: 30px;
  }
}

.activeWrapper {
  background: linear-gradient(90.45deg,
      rgba(11, 46, 76, 0.8) 0.47%,
      rgba(16, 79, 133, 0.8) 104.65%);
  color: white;
}

.inactiveWrapper {
  background: #eae5e5a8;
  color: #111111;
}

.connectedSection {
  display: flex;
  justify-content: space-between;
  max-width: 445px;
  width: 100%;
  align-items: center;

  .disconnectButton {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(90.45deg,
        rgba(11, 46, 76, 0.55) 0.47%,
        rgba(16, 79, 133, 0.55) 104.65%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;

    &:hover {
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
    }
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper:hover {
  background: linear-gradient(90.45deg,
      rgba(11, 46, 76, 0.4) 0.47%,
      rgba(16, 79, 133, 0.4) 104.65%);
}

.buttonsSection {
  display: flex;
  align-items: center;
  max-width: 325px;
  width: 100%;
  justify-content: space-between;

  .shareButton {
    font-family: 'DM Sans';
    font-weight: 700;
    height: 50px;
    color: #ffffff;
    text-transform: capitalize;
    display: flex;
    column-gap: 10px;
    padding: 13px 15px;

    &:hover {
      color: #ffffff;
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
      border-radius: 10px;
    }
  }

  .walletButtonConnected {
    color: #0b3456;
    text-transform: none;
    display: flex;
    padding: 13px 45px 13px 13px;
    background-color: white;
    font-family: 'DM Sans';
    font-size: 16px;
    font-weight: 700;
    column-gap: 10px;
    border-radius: 10px;
    max-height: 50px;
    transition: background-color 0.9s ease;

    .connectWalletLabel {
      position: absolute;
      right: -4px;
      bottom: -12px;
      font-size: 12px;
      width: 111px;
      height: 24px;
      border: none;
      color: #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90.06deg, #1d67aa 2.17%, #a3cff4 92.93%);
    }

    &:hover {
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
      color: white;
    }
  }

  .openedModal {
    background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%) !important;
    color: white !important;
  }

  .connectWalletButton {
    text-transform: none;
    display: flex;
    padding: 13px 32px;
    background-color: rgba(255, 255, 255, 0.719);
    font-family: 'DM Sans';
    font-size: 16px;
    font-weight: 700;
    color: #181818;
    column-gap: 10px;
    border-radius: 10px;
    max-height: 50px;
    transition: background-color 0.9s ease;

    &:hover {
      color: #ffffff;
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
    }

    &:disabled {
      color: #181818cf;
    }

    &:active {
      background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%) !important;
      color: white;
    }

    img {
      opacity: 0.81;
    }

    .connectWalletLabel {
      position: absolute;
      right: -4px;
      bottom: -12px;
      font-size: 12px;
      width: 111px;
      height: 24px;
      border: none;
      color: #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90.06deg, #1d67aa 2.17%, #a3cff4 92.93%);
    }
  }

  .shareButtonMobile {
    font-size: 16px;
    color: #0b3456;
    border: 1.5px solid #0b3456;
    border-radius: 10px;
    cursor: pointer;
    text-transform: capitalize;
    column-gap: 10px;
    display: flex;
    font-weight: 550;
    height: 45px;
    width: 124px;
  }
}

.walletsModal {
  position: absolute;
  top: 60px;
  transform: translate(-33%);
  background-color: #ffffff;
  height: 318px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px !important;
  gap: 24px;
  z-index: 99;

  &.centered {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  &.crossmint {
    padding-bottom: 30px;
  }

  @media screen and (max-width: 1650px) {
    transform: none;
    left: auto;
    right: 0;
  }
}

.connectedWalletModal {
  width: 559px;
  right: 0;
}

.disconnectedWalletModal {
  width: 460px;
  right: 0;
}

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.relative {
  position: relative;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

@media screen and (max-width: 1161px) {
  .buttonsSection {
    column-gap: 10px;
    max-width: 340px;
    justify-content: center;
    column-gap: 11px;

    .connectWalletButton,
    .shareButton {
      font-size: 16px;
      width: auto;
      height: auto;
      padding: 5px 10px;

      .connectWalletLabel {
        width: 80px;
        height: 15px;
        font-size: 8px;
        right: -3px;
        bottom: -9px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .buttonsSection {

    .walletButtonConnected,
    .connectWalletButton {
      background: rgba(217, 217, 217, 0.2);
      border-radius: 10px;
      width: 200px;
      height: 45px;
      cursor: pointer;
      display: flex;
      column-gap: 7px;
      font-weight: 550;
      text-transform: none;
    }
  }

  .wrapper {
    width: 80%;
  }

  .connectWalletLabel {
    border: none;
    background: linear-gradient(90.06deg, #1d67aa 2.17%, #a3cff4 92.93%) !important;
    right: 0;
    bottom: -12px;
    position: absolute;
    right: -4px;
    bottom: -12px;
    font-size: 12px;
    width: 111px;
    height: 24px;
    color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .connectedSection {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 445px;
    width: 100%;
    align-items: center;

    .disconnectButton {
      position: absolute;
      right: 0;
      width: 28px;
      height: 28px;
      border-radius: 50%;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  .walletsModal {
    position: fixed;
    width: 90%;
    top: 20%;
    left: 50%;
    height: auto;
    transform: translate(-50%);
    border-radius: 20px !important;
    box-sizing: border-box;
    padding: 0px 20px 44px 20px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.34));

    .close {
      position: absolute;
      top: 16px;
      right: 15px;
      cursor: pointer;
    }

    .title {
      font-size: 16px !important;
    }

    .wrapper {
      width: 75%;
      background-color: #f7f7f7;
      box-sizing: border-box;

      &.activeWrapper {
        background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
      }

      .element {
        font-weight: 500;
      }
    }
  }
}
