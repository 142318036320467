.loaderBox {
  max-width: 1170px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 60px;
  display: flex;
}

.loaderBackground {
  background: rgba(0, 0, 0, 0.34);
  position: fixed;
  z-index: 112;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 150%;
    color: #042037;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.nftPageContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 218px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  background-image: url('../../assets/images/nft.background.svg');
  background-repeat: no-repeat;
  background-size: cover;

  .modalHeader {
    box-sizing: border-box;
    padding: 20px;
    margin: 20px;
    max-width: 1350px;
    width: 100%;
    display: flex;

    .firstSection {
      width: 100%;
      display: flex;

      .imageSection {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-right: 30px;


        .editLogo {
          width: 1.5rem;
          margin-bottom: 0.5rem;
          cursor: pointer;
        }

        .landImg {
          max-width: 491px;
          width: 491px;
          max-height: 491px;
          height: 491px;
          border-radius: 5px;
        }
      }

      .headerInfoBox {
        display: flex;
        flex-direction: column;
        padding: 0px 10px;
        width: 100%;

        .notMintedText {
          max-width: 120px;
          margin-bottom: 15px;
        }

        .landName {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 26px;
          font-size: 24px;
          font-weight: 700;
        }

        .iconBox {
          display: flex;
          align-items: flex-start;
          margin-top: 20px;

          .text {
            font-size: 18px;
          }

          .locationText {
            background-color: rgba(11, 52, 86, 1);
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 14px;
            margin-right: 5px;
            margin-bottom: 5px;
          }

          .locations {
            display: flex;
            flex-wrap: wrap;
          }

          .mapPin {
            width: 19px;
            height: 19px;
            margin-right: 6px;
          }

          .solanaIcon {
            width: 23px;
            height: 23px;
            margin-right: 4px;
          }
        }

        .ownerBox {
          .ownerHeader {
            font-size: 16px;
            color: #161616b2;
          }

          .ownerName {
            font-size: 16px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 120px;
          }

          .walletAddress {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .descriptionBox {
          margin-top: 20px;

          .descriptionHeaderBox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            .descriptionTitle {
              font-size: 20px;
              color: #161616e5;
              font-weight: 600;
            }

            .editLogo {
              margin-left: 1rem;
              width: 1.5rem;
              cursor: pointer;
            }
          }

          .description {
            font-size: 18px;
            color: #161616;

            a {
              color: #a3cff4;
            }

            .showMore {
              background: none;
              color: #104f85c7;
              text-decoration: underline;
              border: none;
              font-size: 18px;
              cursor: pointer;
            }
          }
        }

        .btnBlock {
          margin-top: auto;

          .buyButton {
            background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
            width: 200px;
            height: 45px;
            text-align: center;
            border-radius: 10px;
            color: #ffffff;
            text-transform: none;
            margin-right: 10px;

            &:hover {
              background: linear-gradient(90.45deg, #0b2e4c 0.47%, #104f85 104.65%);
            }
          }
        }
      }
    }

    .landStatusBox {
      text-align: center;

      .status {
        background: linear-gradient(90.06deg, #1d67aa 2.17%, #a3cff4 92.93%);
        border-radius: 5px;
        padding: 8px 0px;
        font-size: 12px;
        font-weight: 600;
        width: 120px;
        color: #ffffff;
      }
    }
  }

  .ownerBox {
    margin-top: 10px;

    .ownerHeader {
      font-size: 12px;
    }

    .ownerName {
      font-size: 12px;
    }
  }

  .descriptionBox {
    .description {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .headerInfoBox {
    .btnBlock {
      .buyButton {
        width: 40% !important;
      }
    }
  }
}

@media screen and (max-width: 868px) {
  .nftPageContainer {
    padding: 150px 0px;
  }
}

@media screen and (max-width: 800px) {
  .landStatusBox {
    margin-left: 30px;
  }
  
  .nftPageContainer {
    background-image: none;

    .modalHeader {
      .firstSection {
        flex-direction: column;

        .landImg {
          margin: 0 auto 30px;
          width: 100%;
          height: 300px;
          object-fit: cover;
          max-width: none;
        }
      }
    }
  }

  .btnBlock {
    margin-top: 25px !important;
  }
}

@media screen and (max-width: 600px) {
  .nftPageContainer {
    padding: 80px 0px;
  }
}

.unmintedDescriptionListItem {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .circle {
    width: 5px;
    height: 5px;
    background-color: #333;
    border-radius: 50%;
    margin-right: 6px;
  }
}
