.mobileNavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0b3456;
  height: 74px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0px 3px 6px #092f4f;
  z-index: 99;
  .activePage {
    padding-left: 10%;
    font-size: 18px;
    font-weight: 700;
    color: #d6e6f4;
  }
  .hamburgerIcon {
    position: absolute;
    right: 28px;
    cursor: pointer;
    z-index: 100;
    &.open {
      animation: selectedManu 0.3s ease-out both;

      @keyframes selectedManu {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(90deg);
        }
      }
    }
    &.close {
      animation: closedManu 0.3s ease-out both;

      @keyframes closedManu {
        0% {
          transform: rotate(90deg);
        }
        100% {
          transform: rotate(0);
        }
      }
    }
  }
}
.mobileNavbarOpen {
  position: fixed;
  top: 74px;
  width: 100%;
  height: calc(100% - 74px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0b3456;
  opacity: 0;
  z-index: 90;
  visibility: hidden;
  overflow-y: auto;
  .mobileTitleList {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 45px 65px 42px 35px;
    overflow: auto;
  }
  &.open {
    animation: slide-in-top 0.3s ease-out both;
    visibility: visible;
    @keyframes slide-in-top {
      0% {
        transform: translateY(-700px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &.close {
    animation: closeAnimation 0.4s ease-out both;

    @keyframes closeAnimation {
      0% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
      100% {
        transform: translateY(-700px);
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
.sidebarOpenBtn {
  display: flex;
  background: #103d66;
  transform: rotate(180deg);
  border-radius: 10px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

.sidebarItem {
  text-decoration: none;
}

.pageContainer {
  transform: translateY(-140px);
  box-sizing: border-box;
  padding: 80px 0px 0px 100px;
  position: relative;

  .navigateBtnsSection {
    display: flex;
    max-width: 700px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 17px;
    margin: 50px auto 0;
    column-gap: 17px;

    .prevPageButton,
    .nextPageButton {
      max-width: 279px;
      width: 100%;
      padding: 11px 31px;
      background: rgba(11, 52, 86, 0.83);
      border-radius: 72px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.3s;
      div {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
        p {
          font-family: 'DM Sans';
          line-height: 150%;
          color: #ffffff;
        }
        p:first-child {
          font-weight: 400;
          font-size: 11px;
        }
        p:last-child {
          font-weight: 500;
          font-size: 14px;
        }
      }
      &:hover {
        background: linear-gradient(270.45deg, rgba(16, 79, 133, 0.78) 0.47%, #0b2e4c 104.65%);
      }

      &:active {
        background: #0b3456;
      }
    }

    .prevPageButton {
      &:hover {
        background: linear-gradient(90.45deg, rgba(16, 79, 133, 0.78) 0.47%, #0b2e4c 104.65%);
      }

      div {
        align-items: flex-end;
      }
    }
  }

  @media screen and(max-width: 600px) {
    margin-top: 130px;
  }
  .backToHomePage {
    display: flex;
    color: #5b5b5b;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 1161px) {
  .pageContainer {
    padding: 45px 15px 15px 15px;
    &.closed {
      padding: 45px 15px 15px 45px;
    }
  }
}
@media screen and (max-width: 600px) {
  .pageContainer {
    padding: 46px 20px 126px 20px;

    .navigateBtnsSection {
      max-width: 341px;
    }
  }
}
