.aboutInfo {
  background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 70px 232px 70px 212px;
  margin-top: 100px;
  flex-wrap: wrap;
  column-gap: 30px;

  .logo {
    font-size: 36px;
    line-height: 150%;
    color: white;
    font-family: 'DM Sans';
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 30px;
  }

  .about {
    font-size: 23px;
    line-height: 150%;
    color: white;
    font-family: 'DM Sans';
    max-width: 450px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1000px) {
  .aboutInfo {
    padding: 70px 40px;
  }
}

@media screen and (max-width: 600px) {
  .aboutInfo {
    padding: 24px 20px;
    flex-direction: column;
    margin-top: 70px;
    width: 100%;
    padding: 20px;

    .logo {
      font-weight: 500;
      font-size: 20px;
      column-gap: 10px;

      img {
        margin-left: auto;
        width: 129px;
        height: 36px;
      }
    }

    .about {
      font-size: 18px;
      margin-top: 20px;
    }
  }
}
