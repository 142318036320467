.landsInfoIcon {
  position: absolute !important;
  left: 30px !important;
  top: 135px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 4px;
}
