$bp-border-color: #0b3456;
$bp-bg-color: #0b3456;
$bp-svg: "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E";

.relative {
    position: relative;
}
.mintLandContainer {
  .buttons {
    width: 285px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancelButton {
      width: 120px;
      height: 45px;
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
      border-radius: 10px;
      color: #ffffff;
      font-size: 11px;
    }

    .combineButton {
      width: 120px;
      height: 45px;
      background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
      border-radius: 10px;
      color: #ffffff;
      font-size: 11px;
      &:disabled {
        background: linear-gradient(
          270.15deg,
          rgba(9, 52, 88, 0.55) -3.93%,
          rgba(13, 41, 66, 0.55) 99.82%
        );
      }
    }
  }
  .logos {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .uploadedImg {
      width: 100px;
      height: 100px;
    }

    .about {
      font-weight: 700;
      font-size: 22px;
    }
  }

  position: fixed;
  top: 60px;
  right: 0;
  max-width: 460px;
  width: 100%;
  background-color: #ffffff;
//   box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 73px 72px 43px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../src/assets/images/mintModalBackground.png');
  background-size: 105% 105%;
  background-repeat: no-repeat;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 0px;
    border: none;
    cursor: pointer;
  }
  .inputsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 44px;
    width: 100%;
    box-sizing: border-box;
    .landNameInputContainer {
      width: 100%;
      position: relative;
      .placeholderDefault {
        position: absolute;
        display: flex;
        top: 8px;
        left: 27px;
        font-size: 14px;
        color: #78777793;
        pointer-events: none;
        .red {
          color: red;
        }
      }
      .landNameInput {
        border: 0.7px solid #0b345680;
        border-radius: 33px;
        width: 100%;
        div {
          &::before {
            border-bottom: none;
          }
          &::after {
            border-bottom: none;
          }
          input {
            box-sizing: border-box;
            padding-left: 26px;
            height: 37px;
            &::placeholder {
              font-size: 14px;
            }
          }
        }
      }
    }

    .royaltyImgInputsContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 14px 0;
      column-gap: 9px;
      .combinedLandNameInputContainer {
        width: 75%;
        position: relative;
        .placeholderDefault {
          position: absolute;
          display: flex;
          top: 8px;
          left: 27px;
          font-size: 14px;
          color: #78777793;
          .red {
            color: red;
          }
        }
        .combinedLandNameInput {
          border: 0.7px solid #0b345680;
          border-radius: 33px;
          width: 100%;
          div {
            &::before {
              border-bottom: none;
            }
            &::after {
              border-bottom: none;
            }
            input {
              box-sizing: border-box;
              padding-left: 26px;
              height: 37px;
              &::placeholder {
                font-size: 14px;
              }
            }
          }
        }
      }
      .royaltyInputContainer {
        width: 38%;
        position: relative;
      }

      .landImgInputContainer {
        display: flex;
        position: relative;
        border: 0.7px solid #0b345680;
        border-radius: 33px;
        width: 52%;
        background-color: #ffffff;
        z-index: 0;
        cursor: pointer;
        .landImgText {
          position: absolute;
          display: flex;
          top: 8px;
          left: 27px;
          font-size: 14px;
          color: #78777793;
        }
        .landImgInput {
          position: absolute;
          left: 0;
          top: 0;
          div {
            &::before {
              border-bottom: none;
            }
            &::after {
              border-bottom: none;
            }
            input {
              box-sizing: border-box;
              padding-left: 26px;
              height: 37px;
              opacity: 0;
              width: 100%;
              cursor: pointer;
              &::-webkit-file-upload-button {
                display: none;
              }
              &::file-selector-button {
                display: none;
              }
              &::placeholder {
                font-size: 14px;
              }
            }
          }
        }
        .attachFileIcon {
          position: absolute;
          right: 5px;
          top: 5px;
          color: #0b3456;
          transform: rotate(30deg);
          pointer-events: none;
        }
        .landImgBox {
          border: 0.7px solid rgba(11, 52, 86, 0.3);
          border-radius: 33px;
          position: absolute;
          top: 3px;
          left: 6px;
          width: 90px;
          box-sizing: border-box;
          padding: 3px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: default;
          .uploadedImg {
            width: 24px;
            height: 24px;
            border-radius: 3px;
          }
          .removeImgIcon {
            cursor: pointer;
          }
        }
      }
    }
    .descriptionInput {
      width: 100%;
      height: 123px;
      border: 0.7px solid #0b345680;
      border-radius: 10px;
      div {
        &::before {
          border-bottom: none;
        }
        &::after {
          border-bottom: none;
        }
        textarea {
          box-sizing: border-box;
          padding: 14px 10px 20px 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: rgba(8, 54, 92, 0.5);
  }
  .infoBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 11px 0px 11px 0px;
    .infoBoxTitle {
      font-size: 18px;
      color: #323e51;
      font-weight: 700;
    }
    .info {
      display: flex;
      align-items: center;
      height: 14px;
      img {
        height: 16px;
      }
      .location {
        font-family: 'DM Sans';
        margin-left: 6px;
        font-size: 16px;
        font-weight: 700;
      }
      .secondText {
        font-family: 'DM Sans';
        margin-left: 6px;
      }
    }
  }
  .checkboxContainer {
    margin-top: 24px;
    margin-bottom: 62px;
    width: 100%;
    display: flex;

    .text {
      font-size: 12px;
      margin-left: 6px;
    }
  }
  .modalTitle {
    font-family: 'DM Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #161616;
    margin-bottom: 43px;
  }

  .btnsSection {
    display: flex;
    max-width: 265px;
    justify-content: space-between;
    width: 100%;
    button {
      font-family: 'DM Sans';
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #ffffff;
      border-radius: 10px;
      max-width: 120px;
      width: 100%;
      padding: 12px 0;
      text-transform: none;
    }

    @media screen and (max-width:365px) {
      gap: 6px;
    }
    button:first-child {
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
    }
    .mintActiveBtn {
      background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
      &:hover {
        background: linear-gradient(
          90.45deg,
          rgba(11, 46, 76, 0.8) 0.47%,
          rgba(16, 79, 133, 0.624) 104.65%
        );
      }
    }
    .mintDisabledBtn {
      background: linear-gradient(
        270.15deg,
        rgba(9, 52, 88, 0.55) -3.93%,
        rgba(13, 41, 66, 0.55) 99.82%
      );
    }
  }

  .content {
    margin-bottom: 8px;
    max-width: 341px;
    width: 100%;
    p {
      font-family: 'DM Sans';
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin-bottom: 6px;
    }
  }
}

@media screen and (max-width: 600px) {
  .mintLandContainer {
    border-radius: 30px;
    width: 92.5%;
    height: 660px;
    overflow-y: auto;
    padding: 30px 26px 20px 26px;

    &::-webkit-scrollbar {
      width: 2px;
      display: none;
    }

    .inputsContainer {
      margin-bottom: 15px;
      .royaltyImgInputsContainer {
        flex-direction: column;
        .royaltyInputContainer {
          width: 90%;
          margin-bottom: 9px;
          .infoIconBox {
            &:hover::before {
              padding: 6px;
              z-index: 99;
              width: 240px;
              top: 0px;
              left: -240px;
            }
          }
          .royaltyInput {
            width: 100%;
          }
        }

        .landImgInputContainer {
          width: 100%;
          height: 38px;
          .attachFileIcon {
            top: 6px;
          }
        }
        .descriptionInput {
          width: 100%;
          height: 72px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 10px;
        }
      }
    }
    .modalTitle {
      max-width: 219px;
      text-align: center;
    }

    .content {
      max-width: 219px;
      margin-bottom: 42px;
    }
    .infoBox {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 11px 0px 11px 0px;
      .infoBoxTitle {
        font-size: 14px;
      }
      .info {
        display: flex;
        align-items: center;
        height: 14px;
        img {
          height: 16px;
        }
        .secondText {
          margin-left: 6px;
        }
      }
    }
    .checkboxContainer {
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-height: 820px) and (min-width: 600px) {
  .mintLandContainer {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      margin-right: 20px;
    }
    border-radius: 30px;
  }
}
@media screen and (max-height: 750px) {
  .mintLandContainer {
    height: 70vh;
  }
}
