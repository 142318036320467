.modal {
  max-width: 1225px;
  width: 100%;
  position: fixed;
  display: flex;
  gap: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video {
  width: 100%;
}

.closeModalBtn {
  cursor: pointer;
}

.closeModalBtnContainer {
  top: 0;
  right: 0;
  z-index: 10;
  position: absolute;
}

.modalContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 200;
  background-color: rgba(38, 38, 38, 0.43);
}

.youTubeContainer {
  height: 651px;
  max-width: 1236px;
  width: 100%;
  border-radius: 20px;
}
.videoWrapper {
  border: 1px solid #ffffff;
  width: 92%;
  height: 99%;
  border-radius: 20px;
  background-color: #000000;
  overflow: hidden;
}

.ytp-title-text {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}
@media screen and (max-width: 900px) {
  .modalContainer {
    z-index: 100;
    top: 0;

    .modal {
      height: 80%;
      width: 100%;

      .videoWrapper {
        border-radius: 5px;

        .youTubeContainer {
          .iframeClass {
            height: 45%;
            width: 100%;

            .ytp-title-channel-logo {
              font-size: 15px !important;
              width: 200px !important;
            }
          }
        }

        .closeModalBtnContainer {
          top: -25px;
          right: 15px;

          .closeModalBtn {
            width: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .modalContainer {
    z-index: 100;
    top: 0;

    .video {
      .ytp-title-channel-logo {
        font-size: 15px !important;
        width: 200px !important;
      }
    }

    .modal {
      height: 192px;
      width: 100%;
      top: 330px;

      .videoWrapper {
        border-radius: 5px;

        .youTubeContainer {
          .iframeClass {
            height: 192px;
            width: 100%;

            .ytp-title-channel-logo {
              font-size: 15px !important;
              width: 200px !important;
            }
          }
        }

        .closeModalBtnContainer {
          top: -25px;
          right: 15px;

          .closeModalBtn {
            width: 20px;
          }
        }
      }
    }
  }
}
