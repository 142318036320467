.content {
  display: flex;
  width: 100%;

  .mapWrapper {
    max-height: calc(100vh - 110px);
    width: 100%;
    display: flex;
    flex-direction: column;

    .lands {
      max-width: 943px;
      column-gap: 27px;
      padding: 16px 0 15px 37px;
      width: 100%;
      background: #ffffff;
      border-radius: 20px 20px 0px 0px;
      display: flex;
      align-items: center;

      .landType {
        display: flex;
        align-items: center;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 550;
        font-size: 14px;
        line-height: 150%;

        .circle {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 6px;
        }

        .first {
          background: #99e1d4;
        }

        .second {
          background: #3c9383;
        }

        .third {
          background: #ef9b6c;
        }

        .fourth {
          background: #1d8df4;
        }
      }
    }
    .container {
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      position: relative;

      .selectSection {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        border-radius: 5px;
        margin-left: auto;
        position: absolute;
        left: 30px;
        top: 40px;
        cursor: pointer;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 38px;
          background-color: #ffffffe6;
          border-radius: 4px;

          .chartImg {
            width: 16px;
            height: 16px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .mapPinImg {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 48px;
          height: 38px;
          background-color: #0b3456;
          border-radius: 4px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .zoomBtns {
        display: flex;
        position: initial;
        flex-direction: column;
        row-gap: 16px;
        padding-top: 53px;
        margin-right: 11px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(48px, 439px);

        button {
          width: 37px;
          height: 37px;
          border-radius: 50%;
          min-width: 37px;
          background: rgba(255, 255, 255, 0.9);
          font-size: 30px;

          img {
            width: 15px;
          }
        }

        button:hover {
          background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
        }
      }

      .loader {
        margin-top: 100px;
      }

      .hidden {
        display: none;
      }
      .canvasBox {
        max-width: 100vw;
        max-height: calc(100vh - 110px);
        width: 100vw;
        height: calc(100vh - 110px);
        display: flex;
        align-items: center;
        justify-content: center;
        .canvas {
          background: linear-gradient(rgb(22, 4, 53), rgb(30, 41, 110), rgb(52, 87, 139));
        }
      }
    }
  }

  @media screen and (max-width: 1056px) {
    .mapWrapper {
      .container {
        .zoomBtns {
          left: -20px;
          top: -30px;
          button {
            background: rgba(255, 255, 255, 0.92);
          }
        }
      }
      .lands {
        max-width: 980px;
        padding: 16px 0 15px;
        column-gap: 10px;
      }
    }
  }
  @media screen and (max-device-width: 1024px) and (orientation: landscape) {
    .mapWrapper {
      .container {
        .zoomBtns {
          position: absolute;
          left: auto;
          top: 0;
          right: 0px;
          transform: none;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .mapWrapper {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 72px);

      .lands {
        max-width: 100%;
        height: fit-content;
        padding: 0px;

        .accordion {
          width: 100%;
          border-radius: 20px 20px 0px 0px;

          .landType {
            margin-left: 20px;

            .dropDown {
              align-self: center;
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}
