@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.drawerContainer {
  display: flex;
  height: 1075px;
  max-width: 1440px;
  box-sizing: border-box;
  width: 100%;
  font-family: "DM Sans", sans-serif;

  .titleListContainer {
    box-sizing: border-box;
    width: 440px;
    height: 100%;
    padding: 122px 23px 44px 30px;
    background-color: #f7f7f7;

    ::-webkit-scrollbar {
      width: 11px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 60, 134, 0.2);
      border-radius: 9px;
    }

    ::-webkit-scrollbar-track {
      background-color: #ffffff;
    }

    .titleList {
      width: 100%;
      height: 100%;
      overflow: auto;

      .titleListItem {
        margin-bottom: 31px;

        width: 90%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        p {
          font-size: 18px !important;
          font-family: "DM Sans";
        }
      }

      .selectedTitleListItem {
        margin-bottom: 31px;
        color: #003c86;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        p {
          font-family: "DM Sans";
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    max-width: 1010px;
    box-sizing: border-box;
    height: 100%;
    padding: 0 37px 57px 59px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.22);
    ::-webkit-scrollbar {
      width: 11px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 60, 134, 0.2);
      border-radius: 9px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    .contentTitle {
      margin-top: 62px;
      color: #003c86;
      font-weight: 600;

      p {
        font-size: 25px;
        font-weight: 700 !important;
        margin-bottom: 36px;
      }
    }

    .contentBox {
      flex-grow: 1;
      overflow: auto;
      padding-right: 51px;

      @media screen and (max-width: 1500px) {
        padding-left: 10px;
        padding-right: 20px;
      }

      .firstSentence {
        width: 100%;
        margin-bottom: 23px;
        font-family: "DM Sans";
        font-weight: 700;
        font-size: 18px;
        line-height: 151%;
        color: #161616;
      }

      .content {
        box-sizing: border-box;
        color: #161616;
        font-size: 18px;
        width: 100%;
        height: 100%;
        white-space: pre-line;
        font-family: "DM Sans";
        font-style: normal;
        margin-bottom: 23px;
      }
      .title {
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .drawerContainer {
    .contentContainer {
      padding: 0 10px 10px;

      .contentTitle {
        p {
          font-size: 22px;
        }
      }

      .contentBox {
        .firstSentence {
          font-size: 12px;
        }

        .content {
          font-size: 12px;
        }
      }
    }

    .titleListContainer {
      padding: 100px 10px;
      min-width: 320px;

      .titleList {
        .selectedTitleListItem {
          font-size: 12px;
        }
        .titleListItem {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .drawerContainer {
    .titleListContainer {
      min-width: 220px;
    }
  }
}

@media screen and (max-width: 800px) {
  .drawerContainer {
    .titleListContainer {
      min-width: 220px;
    }
  }
}

.link {
  text-decoration: none;
  color: #161616;
}

.accordionContainer {
  width: 100%;

  .accordion {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    &:before {
      display: none;
    }
    box-shadow: none;
    background-color: #f7f7f7;

    .accordionHeaderBox {
      padding-left: 28px !important;

      .accordionHeader {
        p {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
        }
      }

      .selectedAccordionHeader {
        p {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          color: #0b3456;
        }
      }
    }

    .accordionItem {
      background-color: #ffffff;
      padding-left: 28px !important;
      padding-top: 0px !important;

      .accordionText {
        .content {
          box-sizing: border-box;
          color: #161616;
          font-size: 18px;
          width: 100%;
          height: 100%;
          white-space: pre-line;
          font-family: "DM Sans";
          font-style: normal;
          margin-bottom: 13px;
        }
      }

      .divider {
        background-color: rgba(11, 52, 86, 0.5);
        margin-bottom: 30px;
      }
    }
  }
}
