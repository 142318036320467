.wrapper {
  display: flex;
  flex-direction: column;
  padding: 109px 0 0 0 !important;
  max-width: 100% !important;

  .errorMessage {
    position: absolute;
    font-size: 12px;
    left: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    margin-top: 9px;
    color: #cb2934;
  }

  .main {
    background-image: url('../../assets/images/backgroundContact.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;

    .leftSection {
      max-width: 580px;
      width: 100%;
      padding-top: 153px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .descWrapper {
        display: flex;
        justify-content: center;
        padding: 0;
        .description {
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 146%;
          letter-spacing: 0.01em;
          color: #ffffff;
          margin-bottom: 102px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 55px;
        .infoData {
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 41px;
          letter-spacing: 0.03em;
          color: #ffffff;
          display: flex;
          align-items: center;
          gap: 21px;
          .link {
            text-decoration: none;
            color: #ffffff;
          }
        }
        .hoverEffect {
          cursor: pointer;
        }
      }
    }
  }
  .second {
    display: flex;
    justify-content: center;
    max-width: 705px;
    width: 100%;
    padding: 30px 0 62px;

    .touch {
      background-color: #fff;
      max-width: 655px;
      width: 100%;
      border-radius: 14px;
      padding: 58px 58px 87px;

      .title {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 47px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #0c4374;
        text-align: center;
        margin-bottom: 108px;
      }

      .box {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;

        .topSection {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 50px;
        }

        .phoneInputContainerError {
          border: 0.7px solid rgba(203, 41, 52, 0.7) !important;
          ::placeholder {
            color: rgba(203, 41, 52, 0.5);
          }
          .numberDropdownButton {
            border-color: rgba(203, 41, 52, 0.7);
          }
        }

        .phoneInputContainer {
          position: relative;
          border: 0.7px solid #494949;
          border-radius: 30px;
          max-width: 340px;
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .phoneErrorMessage {
            position: absolute;
            top: 56px;
            font-size: 12px;
            left: 25px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            margin-top: 9px;
            color: #cb2934;
          }
          .numberInputField {
            border-radius: 8px !important;
            margin: 0px !important;
          }

          .numberDropdownButton {
            margin-left: 20px;
            background-color: unset !important;
            border-top: none !important;
            border-bottom: none !important;
            border-left: none !important;

            div {
              display: flex !important;
              margin-right: 10px !important;
            }
          }

          ul {
            li {
              display: flex !important;
              justify-content: space-between !important;
              margin: 8px !important;
            }
          }

          .phoneNumberInput {
            border-left: 1px solid #494949 !important;
            margin-left: 10px;
          }

          .phoneNumberInput {
            border: none !important;
            border-radius: 35px !important;
            height: 35px !important;
            padding-left: 60px;
            width: 90%;
          }

          .phoneNumberInput:focus {
            border-color: #494949 !important;
            outline: none !important;
          }
        }

        .topSectionContainer2 {
          margin-bottom: 50px;
        }

        .input {
          width: 100%;
          border: 0.7px solid #494949;
          border-radius: 33px;
          box-sizing: border-box;
          padding-left: 27px;
          font-family: 'DM Sans';
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          color: #787777;
          ::placeholder {
            font-family: 'DM Sans';
          }

          &.error {
            border-color: #cb2934;
            ::placeholder {
              color: #cb2934;
            }
          }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        .btn {
          width: 173px;
          height: 50px;
          background: linear-gradient(270.15deg, #093458 -3.93%, #0d2942 99.82%);
          border-radius: 10px;
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
          text-transform: none;
          margin: 0 auto;
          &:disabled {
            color: #181818cf;
            background: #c7c7c7;
          }
        }
        .btn:hover {
          background: linear-gradient(90.45deg, #0b2e4c 0.47%, rgba(16, 79, 133, 0.78) 104.65%);
        }
      }
    }
    .icons {
      display: flex;
      gap: 42px;
      margin-top: 25px;
      justify-content: center;
      .socialIcon {
        padding: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease;
        cursor: pointer;
      }
      .socialIcon:hover {
        transform: scale(1.1);
      }
    }
  }
}

fieldset {
  display: none;
}

@media screen and (max-width: 1360px) {
  .wrapper {
    .main {
      flex-wrap: wrap;
      row-gap: 41px;
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding-top: 70px !important;
    .errorMessage {
      margin-top: 2px;
    }
    .main {
      .second {
        padding: 0 20px;
        margin-bottom: 108px;

        .touch {
          padding: 34px 26px 42px;
          max-width: 282px;

          .box {
            .box {
              .phoneInputContainer {
                .phoneErrorMessage {
                  top: 28px;
                }
              }

              .btn {
                padding: 8px 52px;
                font-size: 14px;
                margin-top: 33px;
              }
              .topSectionContainer2 {
                width: 100%;
                margin-bottom: 16px;

                .input {
                  padding: 0;
                  width: 100%;

                  div {
                    font-size: 12px !important;

                    input {
                      padding: 12px 0 10px 27px;
                      font-size: 12px;
                    }
                  }
                }
              }
              .topSection {
                row-gap: 16px;
                margin-bottom: 16px;
                flex-direction: column;

                .topSectionContainer {
                  width: 100%;

                  .input {
                    padding: 0;
                    width: 100%;

                    div {
                      input {
                        padding: 12px 0 10px 27px;
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }

          .title {
            font-size: 20px;
            margin-bottom: 34px;
          }
        }
      }

      .leftSection {
        padding-top: 53px;

        .info {
          gap: 10px;
          .infoData {
            font-size: 20px;
            img {
              width: 25px;
              height: 25px;
            }
            .link {
              font-size: 20px;
            }
          }
        }
        .descWrapper {
          .description {
            font-size: 18px;
            margin-bottom: 29px;
            max-width: 332px;
            width: 100%;
          }
        }
      }
    }
  }
}
